import React, { useEffect, useState } from 'react'
import { ReactMediaRecorder } from 'react-media-recorder-2'

import { UilCamera, UilMicrophone, UilStopCircle, UilTrash, UilFile } from '@iconscout/react-unicons'
import LinearProgress from '@mui/material/LinearProgress'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { sendMedia } from '~/services/Media'

import { AudioComponent, ButtonComponent } from '~/components'

import { bytesToSize } from '~/validations/bytesToSize'
import { formatTime } from '~/validations/formatTime'

import { IDocumentFiles } from '../ModalDocumentation.interfaces'
import * as S from '../ModalDocumentation.styles'

interface FileUploadProps {
  totalExceed: boolean
  handleTotalExceed: (value: boolean) => void
  setDocumentFiles: React.Dispatch<any>
  documentFiles: any
  totalSize: number
  setTotalSize: React.Dispatch<number>
  isLoadingSendMedia: boolean
  isClassInStatus?: boolean
  setIsLoadingSendMedia: React.Dispatch<boolean>
}

export const FileUpload = ({
  totalExceed,
  handleTotalExceed,
  setDocumentFiles,
  documentFiles,
  totalSize,
  setTotalSize,
  isLoadingSendMedia,
  setIsLoadingSendMedia,
  isClassInStatus,
}: FileUploadProps) => {
  const { width } = useWindowDimensions()
  const isMobile = width <= 768

  const [audioCount, setAudioCount] = useState(1)
  const [isRecording, setIsRecording] = useState(false)
  const [seconds, setSeconds] = useState(0)

  // 30 MB
  const maxDocumentFilesSize = 30 * 1024 * 1024

  // 100 MB
  const maxMediaSize = 100 * 1024 * 1024

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (isRecording) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1)
      }, 1000)
    }
    return () => clearInterval(intervalId)
  }, [isRecording])

  const handleRemoveFile = (name: string) => {
    setDocumentFiles((currentFiles: any) =>
      currentFiles.filter((file: any) => {
        return file.name !== name
      }),
    )
  }

  const handleFilesView = () => {
    return documentFiles?.map((file: IDocumentFiles) => {
      if (file.type === 'audio/wav') {
        return (
          <S.File key={file?.name}>
            <AudioComponent file={file.webkitRelativePath} />

            <S.RemoveFileBtn
              onMouseDown={(e) => {
                e.stopPropagation()
                handleRemoveFile(file?.name)
              }}
            >
              <UilTrash size="14" />
            </S.RemoveFileBtn>
          </S.File>
        )
      }
      return (
        <S.File key={file?.name}>
          <S.FileName>{file?.name}</S.FileName>
          <S.RemoveFileBtn
            onMouseDown={(e) => {
              e.stopPropagation()
              handleRemoveFile(file?.name)
            }}
          >
            <UilTrash size="14" />
          </S.RemoveFileBtn>
        </S.File>
      )
    })
  }

  useEffect(() => {
    if (totalSize > maxDocumentFilesSize) {
      handleTotalExceed(true)
    } else {
      handleTotalExceed(false)
    }
  }, [handleTotalExceed, totalSize, maxDocumentFilesSize])

  useEffect(() => {
    if (documentFiles.length > 0) {
      const totalSize = documentFiles.reduce((acc: any, file: any) => acc + file.size, 0)
      setTotalSize(totalSize)
    } else {
      setTotalSize(0)
    }
  }, [setTotalSize, documentFiles])

  useEffect(() => {
    if (totalSize > maxDocumentFilesSize) {
      handleTotalExceed(true)
    } else {
      handleTotalExceed(false)
    }
  }, [handleTotalExceed, totalSize, maxDocumentFilesSize])

  const renderTotalSize = () => {
    if (totalExceed) {
      return (
        <S.FileSizeExceedSpan>
          Tamanho permitido excedido! Tente separar as fotos em pequenas documentações.
        </S.FileSizeExceedSpan>
      )
    }
    return (
      <S.FileSizeSpan>
        Tamanho permitido: {totalSize === 0 ? 0 : bytesToSize(totalSize)} / {bytesToSize(maxDocumentFilesSize)}
      </S.FileSizeSpan>
    )
  }

  const handleFileUpload = (e: any) => {
    setIsLoadingSendMedia(true)
    const files = e.target.files

    const fileArr: IDocumentFiles[] = []

    if (Array?.from<File>(files)?.reduce((acc: any, file: any) => acc + file.size, 0) > maxDocumentFilesSize) {
      window.alert('Tamanho total dos arquivos excedeu o limite de 30MB')
      setIsLoadingSendMedia(false)
      return false
    }

    for (let i = 0; i < files.length; i++) {
      const { name, size, type, webkitRelativePath, lastModifiedDate, lastModified } = files[i]
      const allowedFileTypes = ['jpg', 'png', 'jpeg', 'mp4', 'mov', 'wmv', 'avi', 'mkv', 'pdf']
      const fileExtesion = name.split('.').at(-1).toLowerCase()
      const formData = new FormData()

      if (!allowedFileTypes.includes(fileExtesion)) {
        window.alert(`Este arquivo não é suportado. O arquivo deve ser do tipo: ${allowedFileTypes.join(', ')}`)
        setIsLoadingSendMedia(false)
        return false
      }

      if (type) {
        formData.append('file', files[i])
        sendMedia({ body: formData })
          .then((mediaId: string) => {
            fileArr.push({
              lastModified: lastModified,
              lastModifiedDate: lastModifiedDate,
              name: name,
              size: size,
              type: type,
              webkitRelativePath: webkitRelativePath,
              file: files[i],
              idMedia: mediaId,
            })

            const resultFiles = [...fileArr, ...documentFiles]
            const uniquesFiles: any[] = []

            resultFiles.filter((file) => {
              const i = uniquesFiles.findIndex((x) => x.name == file.name)

              if (i <= -1) {
                uniquesFiles.push(file)
              }
            })

            setDocumentFiles(uniquesFiles)
            setIsLoadingSendMedia(false)
          })
          .catch((error: any) => {
            setIsLoadingSendMedia(false)
            throw new Error('Error ao enviar a mídia:', error)
          })
      }
    }

    const resultFiles = [...fileArr, ...documentFiles]
    const uniquesFiles: any[] = []

    resultFiles.filter((file) => {
      const i = uniquesFiles.findIndex((x) => x.name == file.name)

      if (i <= -1) {
        uniquesFiles.push(file)
      }
    })

    setDocumentFiles(uniquesFiles)
  }

  const handleAudioUpload = async (blobUrl: string, blob: Blob) => {
    const { size, type } = blob

    if (size > maxMediaSize) {
      window.alert('Tamanho do arquivo excedeu o limite de 100MB')
      return false
    }

    const formData = new FormData()
    formData.append('file', new File([blob], `Áudio-${audioCount}`))

    sendMedia({ body: formData }).then((mediaId: string) => {
      setDocumentFiles((prevState: any) => [
        ...prevState,
        {
          name: `Áudio-${audioCount}`,
          size: size,
          type: type,
          webkitRelativePath: blobUrl,
          file: new File([blob], `Áudio-${audioCount}`),
          idMedia: mediaId,
        },
      ])
      setAudioCount((prevState) => prevState + 1)
    })
  }

  return (
    <>
      <S.FileInputDiv>
        <S.AddFileSpan>Adicione</S.AddFileSpan>

        <ReactMediaRecorder
          audio
          onStop={handleAudioUpload}
          render={({ status, startRecording, stopRecording }) => (
            <>
              {status !== 'recording' ? (
                <ButtonComponent
                  type="button"
                  onClick={() => {
                    startRecording(), setIsRecording(true), setSeconds(0)
                  }}
                  iconStart={<UilMicrophone size="15" />}
                  text={isMobile ? '' : 'Audio'}
                  variant="outline"
                  size="small"
                  disabled={!isClassInStatus}
                  color={!isClassInStatus ? 'unavaible' : undefined}
                  onBlur={stopRecording}
                />
              ) : (
                <ButtonComponent
                  type="button"
                  disabled={!isClassInStatus}
                  color={!isClassInStatus ? 'unavaible' : undefined}
                  onClick={() => {
                    stopRecording(), setIsRecording(false)
                  }}
                  iconStart={<UilStopCircle size="15" />}
                  text={`${formatTime(seconds)} Parar`}
                  variant="outline"
                  size="small"
                />
              )}
            </>
          )}
        />
        <S.FileUpload isClassInStatus={isClassInStatus} onChange={handleFileUpload}>
          <UilCamera size="20" />
          {isMobile ? null : 'Foto ou vídeo'}
          <S.FileUploadInput multiple accept=".jpg,.png,.jpeg,.mp4,.mov,.wmv,.avi,.mkv" disabled={!isClassInStatus} />
        </S.FileUpload>

        <S.FileUpload isClassInStatus={isClassInStatus} onChange={handleFileUpload}>
          <UilFile size="20" />
          {isMobile ? null : 'Documentos PDF'}
          <S.FileUploadInput multiple accept=".pdf" disabled={!isClassInStatus} />
        </S.FileUpload>
      </S.FileInputDiv>
      {renderTotalSize()}

      <S.FilesDisplay>
        {handleFilesView()}

        {isLoadingSendMedia && (
          <>
            <S.ContentLoad>
              <S.LinearProgressContainer>
                <S.SpanLoad>Carregando...</S.SpanLoad>
                <LinearProgress sx={{ height: 7, borderRadius: 1, backgroundColor: '#E0E0E0' }} />
              </S.LinearProgressContainer>
            </S.ContentLoad>
          </>
        )}
      </S.FilesDisplay>
    </>
  )
}
