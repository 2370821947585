import { createContext, useCallback, useContext, useMemo } from 'react'

import { useGetMomentCounter, useGetMomentProfessor } from '~/services/Home'

import { dateOptions } from './default.states'
import { IHomePageContextProps, IHomePageProviderProps } from './interfaces'

const HomePageContext = createContext<IHomePageContextProps>({
  extensiveCurrentDay: '',
  plannedMoments: [],
  planningPendingMoments: [],
  pendencyMoments: [],
  pendingAssessmentsMoments: [],
  isLoadingMoments: false,
  plannedMomentsCount: 0,
  planningPendingMomentsCount: 0,
  pendencyMomentsCount: 0,
  pendingAssessmentsMomentsCount: 0,
  isLoadingCounter: false,
})

const HomePageProvider = ({ children }: IHomePageProviderProps) => {
  const { isLoading: isLoadingMoments, data: dataMomentProfessor } = useGetMomentProfessor()
  const { isLoading: isLoadingCounter, data: dataCounter } = useGetMomentCounter()

  const pendingAssessmentsMomentsCount = dataCounter?.pendingAssessmentsMomentsCount || 0
  const plannedMomentsCount = dataCounter?.plannedMomentsCount || 0
  const planningPendingMomentsCount = dataCounter?.planningPendingMomentsCount || 0
  const pendencyMomentsCount = dataCounter?.pendencyMomentsCount || 0

  const upperCaseFirstLetter = useCallback((date: string) => date.charAt(0).toUpperCase() + date.slice(1), [])

  const extensiveCurrentDay = upperCaseFirstLetter(new Date().toLocaleDateString('pt-br', dateOptions))

  const homePageProviderValues = useMemo(() => {
    return {
      extensiveCurrentDay,
      pendingAssessmentsMoments: dataMomentProfessor?.pendingAssessmentsMoments || [],
      plannedMoments: dataMomentProfessor?.plannedMoments || [],
      planningPendingMoments: dataMomentProfessor?.planningPendingMoments || [],
      pendencyMoments: dataMomentProfessor?.pendencyMoments || [],
      isLoadingMoments,
      pendingAssessmentsMomentsCount,
      plannedMomentsCount,
      planningPendingMomentsCount,
      pendencyMomentsCount,
      isLoadingCounter,
    }
  }, [
    extensiveCurrentDay,
    dataMomentProfessor?.pendingAssessmentsMoments,
    dataMomentProfessor?.plannedMoments,
    dataMomentProfessor?.planningPendingMoments,
    dataMomentProfessor?.pendencyMoments,
    isLoadingMoments,
    pendingAssessmentsMomentsCount,
    plannedMomentsCount,
    planningPendingMomentsCount,
    pendencyMomentsCount,
    isLoadingCounter,
  ])

  return <HomePageContext.Provider value={homePageProviderValues}>{children}</HomePageContext.Provider>
}

const useHomePageContext = () => useContext(HomePageContext)

export { HomePageProvider, useHomePageContext }
