import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { debounce } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { gaEvents } from '~/events'
import { useGetTrailsDetailsQuery } from '~/queries/autogenerate/hooks'

import { useAuth } from '~/_context/Auth'
import { useSnackbar } from '~/_context/Snackbar'

import { graphqlRequestClient, useQueryGraphQLClient } from '~/server/GraphQLClient'

import { useServiceUpdateProjectStatus } from '~/services/Lesson'
import { useServiceManageStudentsProject } from '~/services/ManageStudents'
import { useGetProjectInformation } from '~/services/Project'
import { useGetStudents, useGetStudentsGroupProject } from '~/services/Students'

import { GET_CLASS_INFOS_BY_CLASSID } from '../../Trails/ListTrailsProject/schemas.queries'
import { IProjectPageContextProps, IProjectPageProviderProps } from './interfaces'

const ProjectPageContext = createContext<IProjectPageContextProps>({
  isLoading: false,
  data: undefined,
  handleManageStudents: () => Object,
  dataDetails: [],
  dataStudentsPayload: {},
  isLoadingManageStudents: false,
  isLoadingStudentsGroup: false,
  dataStudents: {},
  isLoadingDetails: false,
  loadingDataModal: false,
  loadingDataLesson: false,
  modalOpen: false,
  showSucessModal: false,
  openModalSucess: false,
  showTimeModal: false,
  showCancelModalLesson: false,
  status: '',
  newStatus: '',
  newStatusCode: '',
  statusCode: '',
  statusResponse: '',
  setStatus: () => Object,
  setModalOpen: () => Object,
  setShowTimeModal: () => Object,
  setShowTimeModalEM: () => Object,
  setShowCancelModalLesson: () => Object,
  handleLeaveTrail: () => Object,
  handleChangeStatus: () => Object,
  handleChangeStatusSheduled: () => Object,
  handleLeaveSucessModal: () => Object,
  handleOpenModalSucess: () => Object,
  handleCloseModalSucess: () => Object,
  handleLeaveTrailReSchedule: () => Object,
  refetchProjectInfo: async () => Promise.resolve(),
  isSameUser: false,
  showCancelModal: false,
  isClassInStatus: true,
})

const ProjectPageProvider = ({ children }: IProjectPageProviderProps) => {
  const { projectId, idClass } = useParams()

  const { user } = useAuth()

  const { data, isLoading, refetch: refetchProjectInfo, remove } = useGetProjectInformation(Number(projectId))

  const isClassInStatus = data?.class?.inStatus

  const coStage = data?.class?.schoolGrade?.grade.coStage

  const idUserProfessor = data?.idUserProfessor

  const isSameUser = user?.id_user === idUserProfessor

  const queryClient = useQueryClient()
  const { showSnackbarError } = useSnackbar()
  const navigate = useNavigate()
  const [newStatus, setNewStatus] = useState(null)
  const [newStatusCode, setNewCode] = useState(null)
  const [status, setStatus] = useState(data?.momentStatus?.coMomentStatus as string)
  const [statusCode, setStatusCode] = useState<string>(data?.momentStatus?.txMomentStatus as string)

  const [showSucessModal, setShowSucessModal] = useState(false)
  const [openModalSucess, setOpenModalSucess] = useState<boolean>(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false)
  const [showTimeModalEM, setShowTimeModalEM] = useState<boolean>(false)
  const idTrack = data?.projectTrackStage?.idProjectTrack
  const [showCancelModalLesson, setShowCancelModalLesson] = useState<boolean>(false)

  useEffect(() => {
    remove()
    refetchProjectInfo().then()
  }, [projectId, remove, refetchProjectInfo])

  const statusContextReSchedule = (value: string) => {
    switch (value) {
      case 'AUPL':
        return 'AVPE'
      case 'PEND':
        return 'AVPE'
      default:
        return 'AVPE'
    }
  }
  const {
    isLoading: isLoadingDetails,
    data: dataDetails,
    refetch: refetchDataDetails,
  } = useGetTrailsDetailsQuery(
    graphqlRequestClient('moment'),
    {
      trackId: data?.projectTrackStage?.idProjectTrack as number,
      idClass: Number(data?.class?.idClass),
    },
    {
      enabled: !!data?.projectTrackStage?.idProjectTrack,
    },
  )

  const {
    mutate: handleLeaveTrail,
    error: errorDataLesson,
    isLoading: loadingDataLesson,
    data: dataResponseLesson,
  } = useServiceUpdateProjectStatus(status, Number(projectId), Number(idClass), {
    onSuccess: () => {
      debounce(() => queryClient.invalidateQueries(['getProjectByID', 'getProjectInformation']), 5000)
    },
  })

  useEffect(() => {
    if (!loadingDataLesson && dataResponseLesson) {
      setOpenModalSucess(true)
    }
  }, [dataResponseLesson, loadingDataLesson, status])

  useEffect(() => {
    if (!loadingDataLesson && dataResponseLesson) {
      setNewStatus(status)
      setNewCode(status)
    }
  }, [dataResponseLesson, loadingDataLesson, status])

  useEffect(() => {
    if (!loadingDataLesson && errorDataLesson) {
      showSnackbarError(errorDataLesson?.response?.data.message)
    }
  }, [errorDataLesson, loadingDataLesson, showSnackbarError])

  useEffect(() => {
    if (!loadingDataLesson && dataResponseLesson) {
      setNewStatus(status)
      setNewCode(status)
    }
  }, [dataResponseLesson, loadingDataLesson, status])

  const {
    isLoading: isLoadingManageStudents,
    data: dataManageStudents,
    error: errorManageStudents,
    mutate: handleManageStudents,
  } = useServiceManageStudentsProject()

  const {
    data: dataStudentsPayload,
    refetch: refetchStudentsGroup,
    isLoading: isLoadingStudentsGroup,
  } = useGetStudentsGroupProject(Number(projectId))

  const { data: dataStudents, refetch: refetchStudents } = useGetStudents(Number(data?.class?.idClass))

  useEffect(() => {
    if (!isLoadingManageStudents && errorManageStudents) {
      showSnackbarError(
        errorManageStudents?.response?.data?.message?.length
          ? errorManageStudents?.response?.data?.message
          : errorManageStudents?.response?.data?.title,
      )
    }
  }, [errorManageStudents, isLoadingManageStudents, showSnackbarError])

  const {
    mutate: handleLeaveTrailReSchedule,
    error: errorDataModal,
    isLoading: loadingDataModal,
    data: dataResponseModal,
  } = useServiceUpdateProjectStatus(
    statusContextReSchedule(data?.momentStatus?.coMomentStatus as string),
    Number(projectId),
    Number(idClass),
    { onSuccess: () => queryClient.invalidateQueries(['getProjectInformation']) },
  )

  useEffect(() => {
    if (!loadingDataModal && errorDataModal) {
      showSnackbarError(errorDataModal?.response?.data.message)
    }
  }, [errorDataModal, loadingDataModal, showSnackbarError])

  const handleOpenModalSucess = useCallback(() => {
    setOpenModalSucess(true)
  }, [])

  useEffect(() => {
    if (!loadingDataModal && dataResponseModal) {
      setModalOpen(true)
      refetchDataDetails().then()
      setShowCancelModalLesson(false)
      handleOpenModalSucess()
    }
  }, [dataResponseModal, handleOpenModalSucess, loadingDataModal, refetchDataDetails, status])

  const handleChangeStatus = useCallback(
    (status: string) => {
      setStatus(status as string)
      setStatusCode(status as string)
      handleLeaveTrail()
    },
    [handleLeaveTrail],
  )

  const handleCloseModalSucess = useCallback(() => {
    setOpenModalSucess(false)
  }, [])

  const handleChangeStatusSheduled = useCallback(
    (status: string) => {
      setStatus(status)
      setStatusCode(status)
      handleLeaveTrailReSchedule()
    },
    [handleLeaveTrailReSchedule],
  )

  const handleLeaveSucessModal = useCallback(() => {
    setShowSucessModal(false)
    handleOpenModalSucess()
  }, [handleOpenModalSucess])

  const statusResponse = data?.momentStatus?.coMomentStatus as string

  useEffect(() => {
    if (!isLoadingManageStudents && dataManageStudents) {
      refetchStudentsGroup().then()
      refetchStudents().then()
      refetchProjectInfo().then()
    }
    if (dataResponseModal?.value) {
      refetchProjectInfo()
    }
  }, [
    dataManageStudents,
    isLoadingManageStudents,
    refetchProjectInfo,
    refetchStudentsGroup,
    refetchStudents,
    dataResponseModal?.value,
  ])

  const handleOpenModalTimeF2 = useCallback(() => {
    setShowTimeModal(true)
  }, [])

  const handleCloseModalTimeF2 = useCallback(() => {
    setShowTimeModal(false)
  }, [])

  const { data: dataClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(data?.class.idClass),
      showDisabledClasses: true,
    },
    {
      enabled: !!data?.class?.idClass && !!data,
    },
  )

  const location = useLocation()

  const handleSchedule = useCallback(() => {
    if (idClass && idTrack) {
      sessionStorage.setItem('previousPath', location.pathname)
      navigate(`/schedule/${idClass}/track/${idTrack}`)
    }
    gaEvents.eventSelectNewTrailButton()
  }, [idClass, idTrack, location.pathname, navigate])

  const projectPageProviderValues = useMemo(() => {
    return {
      isLoading,
      data,
      refetchProjectInfo,
      handleManageStudents,
      isLoadingManageStudents,
      dataStudents,
      dataStudentsPayload,
      isLoadingStudentsGroup,
      statusResponse,
      dataDetails,
      isLoadingDetails,
      loadingDataLesson,
      modalOpen,
      errorDataLesson,
      status,
      isSameUser,
      newStatus,
      newStatusCode,
      statusCode,
      showSucessModal,
      openModalSucess,
      showTimeModal,
      showTimeModalEM,
      showCancelModalLesson,
      setStatus,
      setShowTimeModal,
      setShowTimeModalEM,
      setShowCancelModalLesson,
      setModalOpen,
      loadingDataModal,
      handleLeaveTrail,
      handleChangeStatus,
      handleChangeStatusSheduled,
      handleLeaveSucessModal,
      handleOpenModalSucess,
      handleCloseModalSucess,
      handleLeaveTrailReSchedule,
      refetchStudentsGroup,
      handleOpenModalTimeF2,
      handleCloseModalTimeF2,
      dataClassInfos,
      isClassInStatus,
      handleSchedule,
    }
  }, [
    isLoading,
    data,
    refetchProjectInfo,
    handleManageStudents,
    isLoadingManageStudents,
    statusResponse,
    dataStudents,
    dataStudentsPayload,
    isLoadingStudentsGroup,
    dataDetails,
    isLoadingDetails,
    loadingDataLesson,
    modalOpen,
    errorDataLesson,
    newStatus,
    isSameUser,
    newStatusCode,
    statusCode,
    status,
    setStatus,
    showSucessModal,
    openModalSucess,
    showTimeModal,
    showTimeModalEM,
    showCancelModalLesson,
    setShowTimeModal,
    setShowTimeModalEM,
    setShowCancelModalLesson,
    setModalOpen,
    loadingDataModal,
    handleLeaveTrail,
    handleChangeStatus,
    handleChangeStatusSheduled,
    handleLeaveSucessModal,
    handleOpenModalSucess,
    handleCloseModalSucess,
    handleLeaveTrailReSchedule,
    refetchStudentsGroup,
    handleOpenModalTimeF2,
    handleCloseModalTimeF2,
    dataClassInfos,
    isClassInStatus,
    handleSchedule,
  ])

  return <ProjectPageContext.Provider value={projectPageProviderValues}>{children}</ProjectPageContext.Provider>
}

const useProjectPageContext = () => useContext(ProjectPageContext)

export { ProjectPageProvider, useProjectPageContext }
