import { Avatar, Box, Divider, Tooltip, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import { IStyledProps } from '~/components/Navigation/StepsTabs/StepsTabs.interfaces'

import theme from '~/styles/theme'
interface IStyledPropsCard {
  isView?: boolean
  mobile?: boolean
}

export const Container = styledMUI(Box)<IStyledPropsCard>(({ isView, mobile }) => ({
  display: 'block',
  width: '100%',
  flex: '1 0 0',
  height: isView && mobile ? 'auto' : '100%',
  backgroundColor: theme.colors.highPure,
  borderRadius: '0px 0px 24px 24px',
}))

export const Content = styledMUI(Box)(() => ({
  height: '100%',
  width: '630px',
  maxHeight: '100%',
  alignItems: 'left',
  justifyContent: 'left',
}))

export const ContentInfo = styledMUI(Box)(() => ({
  display: 'flex',
  width: '100%',
  gap: '24px',
  height: '100%',
}))
export const ContentIcon = styledMUI(Box)(() => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.negativeLight,
  borderRadius: '50px',
}))

export const RowContainer = styledMUI(Box)<IStyledProps>(({ mobile }) => ({
  display: 'flex',
  flexDirection: mobile ? 'column' : 'row',
  alignItems: 'center',
  gap: '16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}))

export const BoxBetween = styledMUI(Box)(() => ({
  display: 'flex',
  height: '520px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: '1 0 0',
}))

export const TitleWrapper = styledMUI(Box)<IStyledProps>(({ mobile }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: mobile ? '85%' : '35%',
  height: '100%',
  alignSelf: 'stretch',
  flex: '1 0 0',
}))
export const TitleWrapperMobile = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  marginTop: '16px',
}))

export const Title = styledMUI(Typography)(() => ({
  color: theme.colors.primaryDark,
  fontSize: theme.typography.caption.small.fontSize,
  fontWeight: theme.typography.caption.small.fontWeight,
  lineHeight: theme.typography.caption.small.lineHeight,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '30px',
  zIndex: 10,
  '@media (max-width: 600px)': {
    color: theme.colors.highPure,
    marginTop: '-60px',
  },
}))

export const TitleCardProject = styledMUI(Typography)(() => ({
  color: theme.colors.lowPure,
  fontSize: theme.typography.headings.headingSmall.fontSize,
  fontWeight: theme.typography.headings.headingSmall.fontWeight,
  lineHeight: theme.typography.headings.headingLarge.lineHeight,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
}))

export const WrapperNumber = styledMUI(Box)(() => ({
  marginLeft: '0px',
  display: 'flex',
  alignItems: 'center',
}))

export const ContentSelectDate = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  marginTop: 16,
  marginBottom: 26,
}))

export const ContainerBtn = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 16,
  marginTop: 16,
  marginBottom: 36,
}))

export const NumberBox = styledMUI(Box)(() => ({
  justifySelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '16px',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: theme.colors.midPure,
  color: 'white',
  '@media (max-width: 600px)': {
    width: '24px',
    height: '24px',
    padding: 10,
  },
}))

export const MainBox = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '24px',
  width: '100%',
  justifyContent: 'space-between',
  height: '100%',
  flex: '1 0 0',
  alignSelf: 'stretch',
  gap: 24,
  '@media (max-width: 600px)': {
    padding: '24px',
  },
  '@media (max-width: 430px)': {
    padding: '12px',
  },
  '@media (max-width: 400px)': {
    padding: '2px',
  },
}))

export const ListBox = styledMUI(Box)<IStyledPropsCard>(({ isView, mobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  height: isView && mobile ? 'auto' : '100%',
  boxSizing: 'border-box',
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '8px',
  },
}))

export const ListBoxCalendar = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  height: '100%',
  boxSizing: 'border-box',
  '@media (max-width: 600px)': {
    width: '100%',
    padding: '8px',
  },
}))

export const TooltipLabel = styledMUI(Tooltip)(() => ({}))

export const MiniCardContainer = styledMUI(Box)<IStyledProps>(({ mobile, active }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: mobile && active ? '335px' : mobile && !active ? '230px' : 'min-content',
  marginBottom: mobile && active ? '8px' : '0px',
  marginTop: mobile && active ? '-6px' : '0px',
  alignItems: 'center',
  gap: '8px',
  padding: '8px',
  backgroundColor: theme.colors.midLight,
  borderRadius: '8px',
}))
export const MiniCardContainerCalendar = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: 'min-content',
  alignItems: 'center',
  gap: '8px',
  padding: '8px',
  backgroundColor: theme.colors.midLight,
  borderRadius: '8px',
}))

export const BtnContainer = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '100%',
  padding: 8,
  gap: '4px',
  flex: '2 1 0',
}))

export const RowProfessor = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
}))

export const TextInfoLabel = styledMUI(Typography)(() => ({
  fontSize: '14px',
  maxWidth: '161px',
  lineHeight: '14px',
  fontWeight: 400,
  alignItems: 'center',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

export const DateLabel = styledMUI(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 700,
  alignItems: 'center',
}))
// Componente para o Avatar
export const AvatarDefault = styledMUI(Avatar)(() => ({
  width: '24px',
  height: '24px',
  backgroundColor: theme.colors.highDark,
}))

// Componente para o Divider vertical
export const DividerVertical = styledMUI(Divider)(() => ({
  width: '1px',
  height: '24px',
  backgroundColor: theme.colors.highDark,
  marginX: '8px',
}))

// Componente para o Chip "você"
export const ChipCustom = styledMUI(Box)(() => ({
  display: 'flex',
  height: '20px',
  alignItems: 'center',
  flexShrink: 0,
  backgroundColor: theme.colors.highMedium,

  color: theme.colors.lowLight,
  fontSize: '12px',
  padding: 4,
  fontWeight: 700,
  borderRadius: '4px',
}))

export const BoxIcon = styledMUI(Box)(() => ({
  color: theme.colors.feedback.success.main,
}))

export const ContainerMiniCard = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '6px',
  paddingBottom: '8px',
}))

export const ContainerFooter = styledMUI(Box)(() => ({
  display: 'flex',
  marginTop: '32px',
}))

export const FullDivider = styledMUI(Divider)(() => ({
  display: 'flex',
  width: '120%',
  marginLeft: '-10%',
}))
