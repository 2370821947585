import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UilRocket } from '@iconscout/react-unicons'

import { useAuth } from '~/_context/Auth'

import { AppBarComponent, ModalComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Home'
import { CardFilterComponent } from '~/containers/private/Home/CardFilter/CardFilter'

import { useHomePageContext } from './context'
import { MomentTabView } from './MomentTabView/view'
import * as S from './styles'

export const HomeView = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [activeTab, setActiveTab] = useState<number | null>(0)
  const [_, setStateNewAvatarHome] = useState(localStorage.getItem('@LEKTO:avatar'))
  const [showWelcomeModal, setShowWelcomeModal] = useState(false)

  const [cards, setCards] = useState([
    { id: 0, type: 'toPlan', name: 'para planejar', coStatus: 'PEND', count: 4, checked: true },
    { id: 1, type: 'toPerform', name: 'para realizar e documentar', coStatus: 'AUPL', count: 10, checked: false },
    { id: 2, type: 'toDocumentAndEvaluate', name: 'para avaliar', coStatus: 'AVPE', count: 10, checked: false },
    { id: 3, type: 'pending', name: 'para atualizar', coStatus: 'AUPE', count: 1, checked: false },
  ])

  const {
    isLoadingMoments,
    extensiveCurrentDay,
    pendingAssessmentsMoments,
    plannedMoments,
    planningPendingMoments,
    pendencyMoments,
    pendingAssessmentsMomentsCount,
    plannedMomentsCount,
    planningPendingMomentsCount,
    pendencyMomentsCount,
    isLoadingCounter,
  } = useHomePageContext()

  const queryString = window.location.search

  const params = new URLSearchParams(queryString)

  const coMomentStatus = params.get('coMomentStatus')

  const updateCards = useCallback(() => {
    const index = cards.findIndex((card) => card.coStatus === coMomentStatus)
    if (index !== -1 && !cards[index].checked) {
      return cards.map((card, i) => ({
        ...card,
        checked: i === index,
      }))
    }
    return null
  }, [cards, coMomentStatus])

  useEffect(() => {
    const newCards = updateCards()
    if (newCards) {
      setCards(newCards)
      setActiveTab(newCards.findIndex((card) => card.checked))
    }
  }, [coMomentStatus, updateCards])

  useEffect(() => {
    setCards((prevCards) =>
      prevCards.map((card) => {
        switch (card.type) {
          case 'toPlan':
            return { ...card, count: planningPendingMomentsCount }
          case 'toPerform':
            return { ...card, count: plannedMomentsCount }
          case 'toDocumentAndEvaluate':
            return { ...card, count: pendingAssessmentsMomentsCount }
          case 'pending':
            return { ...card, count: pendencyMomentsCount }
          default:
            return card
        }
      }),
    )
  }, [pendingAssessmentsMomentsCount, plannedMomentsCount, planningPendingMomentsCount, pendencyMomentsCount])

  useEffect(() => {
    if (
      !isLoadingCounter &&
      planningPendingMomentsCount === 0 &&
      plannedMomentsCount === 0 &&
      pendingAssessmentsMomentsCount === 0 &&
      pendencyMomentsCount === 0
    ) {
      setShowWelcomeModal(true)
    }
  }, [
    pendingAssessmentsMomentsCount,
    plannedMomentsCount,
    planningPendingMomentsCount,
    pendencyMomentsCount,
    isLoadingCounter,
  ])

  const handleCardClick = (index: number) => {
    const newCards = cards.map((card, i) => {
      if (i === index) {
        if (card.checked) {
          return card
        } else {
          return { ...card, checked: true }
        }
      } else {
        return { ...card, checked: false }
      }
    })

    setActiveTab(index)
    setCards(newCards)

    const statusParam = newCards[index].coStatus
    navigate(`/home?coMomentStatus=${statusParam}`)
  }

  return (
    <>
      <AppBarComponent setStateNewAvatarHome={setStateNewAvatarHome} key={'appBarHomeView'} />

      <S.ContainerHome>
        <S.ContentHome>
          <HeaderComponent currentDay={extensiveCurrentDay} username={user?.given_name ?? ''} key={'headerHomeView'} />

          <S.CardsContainer component="ul">
            <>
              <S.CardsContainerRow>
                {cards.map((card, index) => (
                  <>
                    <CardFilterComponent
                      onClick={() => handleCardClick(index)}
                      key={card.type + index}
                      index={index}
                      type={card.type}
                      loading={isLoadingCounter}
                      count={card.count}
                      label={card.name}
                      checked={card.checked}
                      data-testid={`card-filter-${card.type}-${index}`}
                    />
                    {index === 2 && <S.Line key={'cardLine' + index} />}
                  </>
                ))}
              </S.CardsContainerRow>

              {activeTab === 0 && planningPendingMoments && (
                <MomentTabView
                  isLoading={isLoadingMoments}
                  moments={planningPendingMoments}
                  type={'planningPendingMoments'}
                />
              )}

              {activeTab === 1 && plannedMoments && (
                <MomentTabView isLoading={isLoadingMoments} moments={plannedMoments} type={'plannedMoments'} />
              )}

              {activeTab === 2 && pendingAssessmentsMoments && (
                <MomentTabView
                  isLoading={isLoadingMoments}
                  moments={pendingAssessmentsMoments}
                  type={'pendingAssessmentsMoments'}
                />
              )}

              {activeTab === 3 && pendencyMoments && (
                <MomentTabView isLoading={isLoadingMoments} moments={pendencyMoments} type={'pendencyMoments'} />
              )}
            </>
          </S.CardsContainer>
        </S.ContentHome>
      </S.ContainerHome>

      <ModalComponent
        maxHeight="88%"
        width="448px"
        open={showWelcomeModal}
        actionCloseModal={() => {
          return
        }}
        isManageStudentsModal={false}
        isStudentsGroupsPdfRequestLoading={false}
        handleDownloadStudentsGroupsPdf={() => {
          return
        }}
      >
        <S.WelcomeModalBox className="welcome-modal-container" data-testid="welcome-modal">
          <S.WelcomeIconBox>
            <UilRocket size="26" color="#0095FF" />
          </S.WelcomeIconBox>

          <S.WelcomeTitle>Olá, {user?.given_name}! Bem vindo à plataforma Lekto!</S.WelcomeTitle>

          <S.WelcomeParagraph>
            Sua jornada se inicia aqui! Para começar, escolha uma das diversas trilhas que a plataforma oferece.
          </S.WelcomeParagraph>

          <S.TrackButton onClick={() => navigate('/classes')} data-testid="track-button">
            <UilRocket size="26" color="#FFF" />
            Escolher trilha
          </S.TrackButton>

          <S.LatterButton onClick={() => setShowWelcomeModal(false)} data-testid="later-button">
            Fazer isso depois
          </S.LatterButton>
        </S.WelcomeModalBox>
      </ModalComponent>
    </>
  )
}
