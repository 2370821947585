import { UilCheck, UilExclamationTriangle, UilQuestion, UilSignout } from '@iconscout/react-unicons'
import dayjs from 'dayjs'

import { AppBarComponent, ModalActionComponent } from '~/components'
import { ModalClassesAvailableToSchedule } from '~/components/Modals/ModalClassesAvailableToSchedule/ModalClassesAvailableToSchedule'

import { useScheduleTrailPageContext } from './context'
import Form from './Form/Form'
import { TrailHeader } from './Header/Header'
import * as S from './styles'

export const ScheduleTrail = () => {
  const {
    dataTrackSchedule,
    dates,
    setDates,
    datesFormatted,
    validateMoment,
    disabledDate,
    actionSchedule,
    loadingRequestSchedule,
    confirmModal,
    handleCloseConfirm,
    openModalSucess,
    confirmModalDesengage,
    handleCloseConfirmDesengage,
    handleDesengageTrail,
    handleCloseSuccess,
    actionDesengage,
    loadingDesengage,
    canDesengage,
    handleDateChangeSchedule,
    classInfo,
    title,
    loadingUpdate,
    handleLeave,
    openModalEtapa,
    handleDesengageEtapa,
    handleCloseDesengageEtapa,
    openModalRealize,
    handleRealize,
    handleCloseRealize,
    dateRealize,
    handleConfirmRealize,
    handleConfirmRemark,
    loadingRealize,
    loadingGet,
    canSchedule,
    handleBack,
    projectType,
    handleDateChange,
    openAvailableClassesToScheduleModal,
    dataAvailableClassesToSchedule,
    actionScheduleLater,
    handleCloseAvailableClassesToScheduleModal,
    handleNavigateToSchedule,
    changedIndexes,
    showRemark,
    showNuOrder,
    openModalConfirmOtherClass,
    handleCloseConfirmOtherClass,
  } = useScheduleTrailPageContext()

  return (
    <>
      <AppBarComponent />
      <S.Container>
        <TrailHeader
          canSchedule={canSchedule}
          actionSchedule={actionSchedule}
          actionDesengage={handleDesengageTrail}
          canDesengage={canDesengage}
          title={title}
          classInfo={classInfo}
          isLoading={loadingGet}
          handleBack={handleBack}
          loadingSchedule={loadingRequestSchedule || loadingUpdate}
        />

        <S.Content>
          <Form
            changedIndexes={changedIndexes}
            validateMoment={validateMoment}
            datesFormatted={datesFormatted}
            dates={dates}
            handleLeave={handleDesengageEtapa}
            projectType={projectType}
            handleRealize={handleRealize}
            handleDateChange={handleDateChange}
            handleDateChangeSchedule={handleDateChangeSchedule}
            dataTrackSchedule={dataTrackSchedule}
            disabledDate={disabledDate}
            setDates={setDates}
            isLoading={loadingGet}
            handleBack={handleBack}
            canDesengage={canDesengage}
            actionDesengage={handleDesengageTrail}
            actionSchedule={actionSchedule}
            canSchedule={canSchedule}
            loadingSchedule={loadingRequestSchedule || loadingUpdate}
            showRemark={showRemark}
            showNuOrder={showNuOrder}
          />
        </S.Content>
      </S.Container>
      <ModalActionComponent
        actionCofirm={() => actionSchedule()}
        icon={<UilQuestion size={25} color="#FF8A00" />}
        title="Tem certeza que deseja realizar as alterações?"
        open={confirmModal}
        loading={false}
        returnBtnText={'Cancelar'}
        description={'Essa ação não poderá ser desfeita.'}
        actionCloseModal={() => handleCloseConfirm()}
        confirmCancelText="Salvar Alterações"
        infoWidth={500}
        isFullModal={true}
        isWarning={true}
      />

      <ModalActionComponent
        title="Alterações realizadas com sucesso"
        open={openModalSucess}
        actionCloseModal={() => handleCloseSuccess()}
        infoWidth={700}
        isFullModal={false}
      />

      <ModalActionComponent
        actionCofirm={actionDesengage}
        icon={<UilSignout size={40} color="#7C8189" />}
        title="Você tem certeza que deseja desocupar essa trilha?"
        open={confirmModalDesengage}
        btnColor={'error'}
        loading={loadingDesengage}
        returnBtnText={'Cancelar'}
        actionCloseModal={() => handleCloseConfirmDesengage()}
        confirmCancelText="Desocupar a trilha"
        infoWidth={100}
        isDesengage
        isFullModal
        dates={dates}
      />

      <ModalActionComponent
        actionCofirm={handleLeave}
        icon={<UilSignout size={40} color="#7C8189" />}
        title="Você tem certeza que deseja desocupar essa etapa?"
        open={openModalEtapa}
        btnColor={'error'}
        returnBtnText={'Cancelar'}
        actionCloseModal={() => handleCloseDesengageEtapa()}
        confirmCancelText="Desocupar etapa"
        infoWidth={100}
        isDesengage
        isFullModal
      />

      <ModalActionComponent
        actionCofirm={handleConfirmRealize}
        icon={<UilCheck size={40} color="#7C8189" />}
        title={`Vamos definir que a etapa foi realizada no dia ${dayjs(dateRealize).format('DD/MM/YYYY')}`}
        open={openModalRealize}
        btnColor={'action'}
        loading={loadingRealize}
        returnBtnText={'Cancelar'}
        actionCofirmRemark={handleConfirmRemark}
        actionCloseModal={() => handleCloseRealize()}
        confirmCancelText="Confirmar realização"
        infoWidth={100}
        isDesengage
        isFullModal
        isRemark={true}
      />

      <ModalActionComponent
        actionCofirm={actionSchedule}
        icon={<UilExclamationTriangle size={40} color="#7C8189" />}
        title={`A sua alteração afetará a data de outras etapas.`}
        open={openModalConfirmOtherClass}
        btnColor={'action'}
        loading={loadingRealize}
        description={'Procure informar os outros professores da trilha  sobre as alterações realizadas.'}
        returnBtnText={'Cancelar'}
        actionCloseModal={() => handleCloseConfirmOtherClass && handleCloseConfirmOtherClass()}
        confirmCancelText="Confirmar alteração"
        infoWidth={100}
        isDesengage
        isFullModal
      />

      <ModalClassesAvailableToSchedule
        openAvailableClassesToScheduleModal={openAvailableClassesToScheduleModal}
        data={dataAvailableClassesToSchedule}
        actionScheduleLater={actionScheduleLater}
        handleCloseAvailableClassesToScheduleModal={handleCloseAvailableClassesToScheduleModal}
        handleNavigateToSchedule={handleNavigateToSchedule}
      />
    </>
  )
}
