import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { UilArrowLeft, UilArrowRight, UilPlus } from '@iconscout/react-unicons'
import { Box, MenuItem, Select } from '@mui/material'
import { gaEvents } from '~/events'
import dayjs from 'dayjs'

import { useAuth } from '~/_context/Auth'

import { IGetTrailListResponse, TrailList } from '~/services/Trails/types'

import {
  AppBarComponent,
  ButtonComponent,
  ContainerComponent,
  TrailCalendar,
  ValidateDataSessionComponent,
} from '~/components'

import { CardTrailComponent, HeaderComponent } from '~/containers/private/Classes/ListClassDetails'

import theme from '~/styles/theme'

import { useListClassDetailsPageContext } from './context'
import * as S from './styles'

export const ListClassDetailsView = () => {
  const location = useLocation()
  const { isLoadingClassInfos, dataClassInfos, isLoadingClassDetails, dataLesson, refetchTrail, isRefetchingLesson } =
    useListClassDetailsPageContext()
  const navigate = useNavigate()
  const { classId } = useParams()
  const { user } = useAuth()

  const [calendarView, setCalendarView] = useState(false)
  const [checkMyTrails, setCheckMyTrails] = useState(false)
  const [trails, setTrails] = useState<IGetTrailListResponse>([])
  const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage
  useEffect(() => {
    if (dataLesson) {
      if (checkMyTrails) {
        setTrails(dataLesson?.filter((trail) => trail.professor[0]?.idUser === user?.id_user))
      } else {
        setTrails(dataLesson)
      }
    }
  }, [dataLesson, checkMyTrails, user?.id_user])

  const goTrail = () => {
    gaEvents.eventSelectTrailButton()
    if (coStage === 'F2' || coStage === 'M3' || coStage === 'M4' || coStage === 'EM') {
      navigate(`/projects-available/${classId}`)
    } else {
      navigate(`/trails-available/${classId}`)
    }
  }

  const [avatas, setAvatas] = useState([])
  const isClassInStatus = dataClassInfos?.class?.items[0]?.inStatus

  useEffect(() => {
    if (!dataClassInfos?.class?.items[0]?.studentClass?.length) return

    const students = dataClassInfos?.class?.items[0]?.studentClass

    const newAvatas = []

    for (const avatar of students) {
      newAvatas.push({
        studentId: avatar?.student?.idStudent,
        label: avatar?.student?.lektoUser?.txName,
        url: avatar?.student?.lektoUser?.txImagePath,
      })
    }

    setAvatas(newAvatas)
  }, [dataClassInfos?.class?.items])

  const handleNavigationTrail = (coMomentStatus: string, idTrack: number) => {
    const coStage = dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage
    if (['EM', 'F2', 'M3', 'M4'].includes(coStage)) {
      navigate(`/trail/${idTrack}/project/${classId}`)
    } else {
      navigate(`/trail/${idTrack}/occupied/${classId}`)
    }
  }

  const renderTrail = useCallback(() => {
    return trails
      ?.sort((a, b) => new Date(a.moment[0].dtSchedule) - new Date(b.moment[0].dtSchedule))
      ?.map((trail, index: number) => {
        return (
          <>
            <CardTrailComponent
              isDisabledClasses={dataClassInfos?.class?.items[0]?.inStatus}
              coStage={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.stage?.coStage}
              actionNavigation={() => handleNavigationTrail(trail.moment[0]?.coMomentStatus, trail.idTrack)}
              key={trail?.idTrack}
              loading={isLoadingClassDetails || isRefetchingLesson}
              idClass={Number(classId)}
              order={index + 1}
              lesson={trails[0]?.moment}
              idTrack={trail.idTrack}
              title={trail.txTitle}
              dataClassInfos={dataClassInfos}
              refetch={refetchTrail}
              idProfessor={trail.professor[0]?.idProfessor}
              idUserProfessor={trail.professor[0].idUser}
              teacherPic={trail.professor[0]?.txImagePath}
              textGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}
              classShift={dataClassInfos?.class?.items[0]?.classShift?.txClassShift}
              textClassname={dataClassInfos?.class?.items[0]?.txName}
              description={trail.txDescription}
              subjects={trail.component}
              teacherList={trail.professor}
              moments={trail.moment.sort((a, b) => new Date(a.dtSchedule) - new Date(b.dtSchedule))}
              teacherName={trail.professor[0]?.txName}
            />
          </>
        )
      })
    // eslint-disable-next-line
  }, [dataClassInfos?.class?.items, handleNavigationTrail, isRefetchingLesson, isLoadingClassDetails, trails])
  const currentMonth = dayjs().month()
  const [period, setPeriod] = useState('Trimestre')
  const [currentPeriod, setCurrentPeriod] = useState(Math.floor(currentMonth / 3) + 1)

  const handlePeriodChange = (event: any) => {
    setPeriod(event.target.value)
    if (event.target.value === 'Semestre') {
      setCurrentPeriod(currentMonth < 6 ? 1 : 2)
    } else if (event.target.value === 'Trimestre') {
      setCurrentPeriod(Math.floor(currentMonth / 3) + 1)
    } else {
      setCurrentPeriod(1)
    }
  }

  const handleNextPeriod = () => {
    if (period === 'Semestre' && currentPeriod < 2) setCurrentPeriod(currentPeriod + 1)
    if (period === 'Trimestre' && currentPeriod < 4) setCurrentPeriod(currentPeriod + 1)
  }

  const handlePreviousPeriod = () => {
    if (currentPeriod > 1) setCurrentPeriod(currentPeriod - 1)
  }

  const handleOpenModalSchedule = useCallback(
    (trail: TrailList) => {
      if (classId && trail.idTrack) {
        sessionStorage.setItem('previousPath', location.pathname)
        navigate(`/schedule/${classId}/track/${trail.idTrack}`)
      }
      gaEvents.eventSelectNewTrailButton()
    },
    [classId, location.pathname, navigate],
  )

  return (
    <>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          isDisabledClasses={dataClassInfos?.class?.items[0]?.inStatus}
          classShift={dataClassInfos?.class?.items[0]?.classShift?.txClassShift}
          loading={isLoadingClassInfos}
          dataClassInfos={dataClassInfos}
          schoolGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.txGrade}
          className={dataClassInfos?.class?.items[0]?.txName}
          avatas={avatas}
          coGrade={dataClassInfos?.class?.items[0]?.schoolGrade?.grade?.coGrade}
        />
        <>
          {!isLoadingClassDetails && !dataLesson?.length ? (
            <S.ContentEmoji>
              <ValidateDataSessionComponent
                screen="trails"
                title="Nenhuma trilha selecionada."
                description="Até o momento, nenhuma trilha foi selecionada para essa turma."
                textAction={'Selecionar Trilha'}
                action={() => goTrail()}
              />
            </S.ContentEmoji>
          ) : (
            <S.ContentList component="ul">
              <ButtonComponent
                size="large"
                variant={'outline'}
                color={!isClassInStatus ? 'disabled' : undefined}
                text="Escolher uma nova trilha"
                onClick={() => isClassInStatus && goTrail()}
                disabled={!isClassInStatus}
                iconEnd={<UilPlus size={24} />}
              />
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <span>Trilhas escolhidas para essa turma</span>
                <S.ContainerBoxBtnCalendar active={calendarView}>
                  <S.SwitchViewListContainer>
                    <S.SwitchViewButton onClick={() => setCalendarView(false)} active={!calendarView}>
                      Lista
                    </S.SwitchViewButton>
                    <S.SwitchViewButton onClick={() => setCalendarView(true)} active={calendarView}>
                      Calendário
                    </S.SwitchViewButton>
                  </S.SwitchViewListContainer>
                  {calendarView && (
                    <S.ContainerButtons>
                      <S.ContentSelectSchedule>
                        <Select
                          autoWidth={true}
                          multiple={false}
                          value={period}
                          onChange={handlePeriodChange}
                          sx={{
                            width: 'fit-content',
                            height: '24px',
                            color: theme.colors.actionPure,
                            '.MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.colors.actionPure,
                              borderWidth: '1px',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.colors.actionPure,
                              borderWidth: '1px',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: theme.colors.actionPure,
                              borderWidth: '1px',
                            },
                            '.MuiSvgIcon-root ': {
                              fill: theme.colors.actionPure,
                              important: 'true',
                            },
                          }}
                        >
                          <MenuItem key={'Anual'} value={'Anual'}>
                            Anual
                          </MenuItem>
                          <MenuItem key={'Semestre'} value={'Semestre'}>
                            Semestral
                          </MenuItem>
                          <MenuItem key={'Trimestre'} value={'Trimestre'}>
                            Trimestral
                          </MenuItem>
                        </Select>
                      </S.ContentSelectSchedule>
                      {(period === 'Semestre' || period === 'Trimestre') && (
                        <S.ContainerButtonsActions>
                          <S.ButtonSelected active={currentPeriod !== 1} onClick={handlePreviousPeriod}>
                            <UilArrowLeft size={16} />
                          </S.ButtonSelected>
                          <S.ButtonSelected
                            active={currentPeriod !== (period === 'Semestre' ? 2 : 4)}
                            onClick={handleNextPeriod}
                          >
                            <UilArrowRight size={16} />
                          </S.ButtonSelected>
                        </S.ContainerButtonsActions>
                      )}
                    </S.ContainerButtons>
                  )}
                </S.ContainerBoxBtnCalendar>
              </Box>
              {!calendarView ? (
                <>
                  {isLoadingClassInfos || isLoadingClassDetails || isRefetchingLesson ? (
                    <>
                      {Object.keys(new Array(2).fill(null)).map((_, index: number) => (
                        <CardTrailComponent loading={isLoadingClassDetails} key={index} />
                      ))}
                    </>
                  ) : (
                    <>{renderTrail()}</>
                  )}
                </>
              ) : (
                <>
                  {trails && (
                    <TrailCalendar
                      trails={trails}
                      actionManagementSchedule={(trail) => handleOpenModalSchedule(trail)}
                      period={period}
                      currentPeriod={currentPeriod}
                    />
                  )}
                </>
              )}
            </S.ContentList>
          )}
        </>
      </ContainerComponent>
    </>
  )
}
