import { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  UilAngleUp,
  UilBars,
  UilBell,
  UilCalender,
  UilEstate,
  UilFileExclamation,
  UilFileQuestionAlt,
  UilQuestionCircle,
  UilRocket,
  UilUserCircle,
  UilUsersAlt,
  UilWhatsapp,
  UilWifiSlash,
} from '@iconscout/react-unicons'
import { Badge, Paper, Tooltip } from '@mui/material'
import { gaEvents } from '~/events'

import { useAppBar } from '~/_context/AppBar'
import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { AvatarComponent, LogoComponent, SettingsMenuComponent } from '~/components'
import NotificationsMenu from '~/components/ui/NotificationsMenu/NotificationsMenu'

import { CardSettingsComponent } from '~/containers/private/Settings'

import { getZendeskRedirectUrl } from '~/validations/routeZendesk'

import packageJson from '../../../../package.json'
import { IAppBarProps, INavIconsProps, IPageProps, ISupportProps } from './AppBar.interfaces'
import * as S from './AppBar.styles'

export const AppBarComponent = ({ setStateNewAvatarHome }: IAppBarProps) => {
  const { width } = useWindowDimensions()
  const { tabActived, handleActiveNewTab } = useAppBar()
  const navigate = useNavigate()
  const [stateNewAvatar, setStateNewAvatar] = useState(localStorage.getItem('@LEKTO:avatar'))
  const offlineMode = localStorage.getItem('@LEKTO:offline') || 'false'
  const [isOfflineMode, setIsOfflineMode] = useState<boolean>(false)

  const { user } = useAuth()

  const [anchorElSupportsMenu, setAnchorElSupportsMenu] = useState<null | HTMLElement>(null)
  const [anchorElNotificationsMenu, setAnchorElNotificationsMenu] = useState<null | HTMLElement>(null)
  const [existeNotifications, setExisteNotifications] = useState<number>(0)
  const [anchorElSettingsMenu, setAnchorElSettingsMenu] = useState<null | HTMLElement>(null)

  const modifyPath = useMemo(() => {
    return (path: string) => {
      return isOfflineMode ? `/offline${path}` : path
    }
  }, [isOfflineMode])

  const pages: IPageProps[] = useMemo(
    () => [
      { id: 1, icon: 'UilEstate', label: 'Início', path: modifyPath('/home') },
      { id: 2, icon: 'UilRocket', label: 'Trilhas', path: '/classes' },
      { id: 3, icon: 'UilCalender', label: 'Agenda', path: '/schedule/week' },
      { id: 4, icon: 'UilUsersAlt', label: 'Painel', path: '/students' },
      ...(width <= 700 ? [{ id: 5, icon: 'UilBars', label: 'Configurações', path: '/settings' }] : []),
    ],
    [modifyPath, width],
  )

  useEffect(() => {
    const currentPath = location.pathname
    const activePage = pages.find((page) => currentPath.includes(page.path))
    if (activePage && activePage.id !== tabActived) {
      handleActiveNewTab(activePage.id)
    }
    // eslint-disable-next-line
  }, [location.pathname, pages, tabActived, handleActiveNewTab])

  const pagesForResponsables: IPageProps[] = [
    {
      id: 1,
      icon: 'UilUserCircle',
      label: 'Portal do Responsável',
      path: '/responsable-students',
      allowedRoles: ['pais'],
    },
  ]

  const pagesByRole = user?.role.includes('pais') ? pagesForResponsables : pages

  const support: ISupportProps[] = [{ id: 1, label: 'Central de apoio' }]

  const handleOpenSupportsMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElSupportsMenu(event.currentTarget)
  }

  const handleCloseSupportsMenu = () => setAnchorElSupportsMenu(null)

  const handleOpenSettingsMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElSettingsMenu(event.currentTarget)
  }

  const handleOpenNotificationsMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNotificationsMenu(event.currentTarget)
  }

  const selectNavIcon = (icon: string, active: number) => {
    const navIcons: INavIconsProps = {
      UilEstate: <UilEstate size={25} color={active === 1 ? '#8C22BC' : '#A3A3A3'} />,
      UilRocket: <UilRocket size={25} color={active === 2 ? '#8C22BC' : '#A3A3A3'} />,
      UilCalender: <UilCalender size={25} color={active === 3 ? '#8C22BC' : '#A3A3A3'} />,
      UilUsersAlt: <UilUsersAlt size={25} color={active === 4 ? '#8C22BC' : '#A3A3A3'} />,
      UilUserCircle: <UilUserCircle size={25} color={active === 1 ? '#8C22BC' : '#A3A3A3'} />,
    }
    if (width <= 700) {
      navIcons.UilBars = <UilBars size={25} color={active === 5 ? '#8C22BC' : '#A3A3A3'} />
    }

    return navIcons[icon as keyof INavIconsProps]
  }

  const handleNavClick = (id: number) => {
    handleActiveNewTab(id)
    navigate(pagesByRole[id - 1].path)
  }

  return (
    <>
      {(width ?? 0) > 700 && (
        <>
          {isOfflineMode && (
            <S.InfoContainer>
              <UilWifiSlash color="#fff" />
              <S.TextInfo>Você está em modo Offline</S.TextInfo>
            </S.InfoContainer>
          )}
          <S.AppBarContainer>
            <S.Content>
              <S.ToolbarContainer>
                <S.ContentNav display={{ xs: 'flex', md: 'flex' }}>
                  {pagesByRole.map((page: IPageProps) => (
                    <Tooltip
                      key={page.id}
                      title={
                        isOfflineMode && (page.id === 2 || page.id === 3 || page.id === 4)
                          ? 'Para acessar essas páginas você precisa sair do modo offline'
                          : ''
                      }
                      placement="bottom"
                    >
                      <S.ContentLink
                        key={page.id}
                        to={(page.id === 2 || page.id === 3 || page.id === 4) && isOfflineMode ? '#' : page.path}
                        actived={String(tabActived === page.id)}
                        onClick={() => {
                          if (page.id === 2) {
                            gaEvents.eventTrailButton()
                          }
                          if (!isOfflineMode) {
                            handleActiveNewTab(page.id)
                          } else if (!(page.id === 2 || page.id === 3 || page.id === 4)) {
                            handleActiveNewTab(page.id)
                          }
                        }}
                      >
                        <>
                          {selectNavIcon(page.icon, tabActived)}

                          <S.TextLink actived={String(tabActived === page.id)}>{page.label}</S.TextLink>
                        </>
                      </S.ContentLink>
                    </Tooltip>
                  ))}
                </S.ContentNav>

                {!user?.role.includes('pais') && (
                  <S.ContentNavSupport display={{ xs: 'flex', md: 'flex' }}>
                    {support.map((page: ISupportProps) => (
                      <Tooltip
                        key={page.label}
                        title={isOfflineMode ? 'Para acessar o suporte você precisa sair do modo offline' : ''}
                        placement="bottom"
                      >
                        <S.ContentSupport
                          actived={anchorElSupportsMenu ? 'true' : 'false'}
                          onClick={(event) => {
                            if (!isOfflineMode) {
                              handleOpenSupportsMenu(event)
                            }
                          }}
                        >
                          <>
                            <UilQuestionCircle size={25} color={anchorElSupportsMenu ? '#8C22BC' : '#a3a3a3'} />
                          </>
                        </S.ContentSupport>
                      </Tooltip>
                    ))}
                    <S.MenuContainer
                      anchorEl={anchorElSupportsMenu}
                      open={Boolean(anchorElSupportsMenu)}
                      onClose={handleCloseSupportsMenu}
                    >
                      <CardSettingsComponent
                        icon={<UilFileQuestionAlt size={20} color="inherit" />}
                        label="Acessar Dúvidas Frequentes"
                        href={'https://suporte.lekto.com.br/hc/pt-br'}
                        isHeaderControl={true}
                      />
                      <CardSettingsComponent
                        icon={<UilWhatsapp size={20} color="inherit" />}
                        label="Falar com Especialista Lekto"
                        href={
                          'https://api.whatsapp.com/send?phone=551140037796&text=Oi!%20Gostaria%20de%20falar%20com%20o%20suporte%20da%20Lekto'
                        }
                        isHeaderControl={true}
                      />
                      <CardSettingsComponent
                        icon={<UilFileExclamation size={20} color="inherit" />}
                        label="Abrir um chamado"
                        href={getZendeskRedirectUrl()}
                        isHeaderControl={true}
                      />
                      <S.TextVersion>{`Versão atual - ${packageJson.version}`}</S.TextVersion>
                    </S.MenuContainer>
                  </S.ContentNavSupport>
                )}

                <S.ContentMenu sx={{ marginLeft: '16px' }}>
                  <Tooltip
                    title={isOfflineMode ? 'Para acessar o suporte você precisa sair do modo offline' : ''}
                    placement="bottom"
                  >
                    <S.ContentNotications
                      actived={anchorElNotificationsMenu ? 'true' : 'false'}
                      onClick={(event) => {
                        if (!isOfflineMode) {
                          handleOpenNotificationsMenu(event)
                        }
                      }}
                    >
                      <Badge
                        badgeContent={existeNotifications}
                        color="secondary"
                        overlap="circular"
                        invisible={existeNotifications === 0}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        sx={{
                          '& .MuiBadge-badge': {
                            fontSize: '0.6rem',
                            height: '16px',
                            minWidth: '16px',
                            padding: '0 4px',
                          },
                        }}
                      >
                        <UilBell
                          size={25}
                          color={anchorElNotificationsMenu || existeNotifications > 0 ? '#8C22BC' : '#a3a3a3'}
                        />
                      </Badge>
                    </S.ContentNotications>
                  </Tooltip>
                  <NotificationsMenu
                    anchorElNotificationsMenu={anchorElNotificationsMenu}
                    setAnchorElNotificationsMenu={setAnchorElNotificationsMenu}
                    setExisteNotifications={setExisteNotifications}
                  />
                </S.ContentMenu>

                <S.ContentMenu sx={{ borderRadius: '16px' }}>
                  {(width ?? 0) >= 930 && (
                    <S.BoxContainer display={{ xs: 'none', md: 'flex' }}>
                      <LogoComponent height={30} width={130} />
                      <S.WrapperUserInfo
                        onClick={(event) => {
                          handleOpenSettingsMenu(event)
                        }}
                        actived={anchorElSettingsMenu ? 'true' : 'false'}
                      >
                        <AvatarComponent
                          photoUrl={stateNewAvatar || user?.picture || ''}
                          label={user?.given_name}
                          size="medium"
                        />
                        <S.UserName actived={anchorElSettingsMenu ? 'true' : 'false'}>
                          {' '}
                          {user?.given_name
                            ? user.given_name.charAt(0).toUpperCase() + user.given_name.slice(1).toLowerCase()
                            : ''}
                        </S.UserName>
                        <S.IconContainer isOpen={!!anchorElSettingsMenu}>
                          <UilAngleUp />
                        </S.IconContainer>
                      </S.WrapperUserInfo>
                      <SettingsMenuComponent
                        anchorElSettingsMenu={anchorElSettingsMenu}
                        setAnchorElSettingsMenu={setAnchorElSettingsMenu}
                        setIsOfflineMode={setIsOfflineMode}
                        setStateNewAvatar={setStateNewAvatar}
                        setStateNewAvatarHome={setStateNewAvatarHome}
                      />
                    </S.BoxContainer>
                  )}
                </S.ContentMenu>
              </S.ToolbarContainer>
            </S.Content>
          </S.AppBarContainer>
        </>
      )}

      {(width ?? 0) <= 700 && (
        <>
          {isOfflineMode && (
            <S.InfoContainer>
              <UilWifiSlash color="#fff" />
              <S.TextInfo>Você está em modo Offline</S.TextInfo>
            </S.InfoContainer>
          )}
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }} elevation={6}>
            <S.BottomNavigationBox showLabels={(width ?? 0) > 500}>
              {pagesByRole.map((page: IPageProps) => (
                <S.BottomNavAction
                  actived={String(tabActived === page.id)}
                  onClick={() => {
                    if (isOfflineMode && (page.id === 2 || page.id === 3 || page.id === 4 || page.id === 5)) {
                      return
                    }
                    handleNavClick(page.id)
                  }}
                  key={page.id}
                  label={page.label}
                  icon={selectNavIcon(page.icon, tabActived)}
                />
              ))}
            </S.BottomNavigationBox>
          </Paper>
        </>
      )}
    </>
  )
}
