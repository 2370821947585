import React, { useEffect, useState } from 'react'

import { UilCalender, UilCheck, UilEditAlt, UilTimesCircle } from '@iconscout/react-unicons'
import { Box, Divider } from '@mui/material'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'

import { useAuth } from '~/_context/Auth'

import useWindowDimensions from '~/_hooks/useWindowDimensions'

import { ButtonComponent, SkeletonComponent } from '~/components'
import { TimeLineLessonComponent } from '~/components/Modals/TimelineLesson/TimelineLesson'

import { formatDayOfWeek } from '~/validations/typeWeek'

import theme from '~/styles/theme'

import { FormProps, MiniCardProps, MomentDates } from '../interfaces'
import * as S from './Form.styles'

const Form: React.FC<FormProps> = ({
  dates,
  disabledDate,
  datesFormatted,
  dataTrackSchedule,
  validateMoment,
  handleDateChange,
  handleDateChangeSchedule,
  handleLeave,
  handleRealize,
  isLoading,
  projectType,
  canDesengage,
  canSchedule,
  actionSchedule,
  actionDesengage,
  loadingSchedule,
  handleBack,
  changedIndexes,
  showRemark,
  showNuOrder,
}) => {
  useEffect(() => {
    if (showRemark === true) {
      handleButtonClick(showNuOrder)
    }
  }, [showNuOrder, showRemark])
  const { user } = useAuth()
  const { width } = useWindowDimensions()
  const isMobile = width && width < 768
  const [openPicker, setOpenPicker] = useState<number | null>(null)
  const MiniCard: React.FC<MiniCardProps> = ({ nuOrder, date, professor, project, moment, type, isRemark }) => {
    const showActions =
      (professor && (professor.idUser === user?.id_user || professor.idUserProfessor === user?.id_user)) || !professor
    const isDisabled = validateMoment(moment)
    return (
      <S.ContainerMiniCard>
        {isMobile && !isRemark && (
          <S.MiniCardContainerCalendar>
            <S.DateLabel>{date}</S.DateLabel>
            <S.DividerVertical orientation="vertical" />
            <S.BoxIcon>
              <UilCheck />
            </S.BoxIcon>
          </S.MiniCardContainerCalendar>
        )}
        <S.MiniCardContainer mobile={isMobile} active={isRemark}>
          {!isMobile && !isRemark && (
            <>
              {project === 1 && type === 'schedule' ? (
                <S.TooltipLabel
                  title={
                    'Professor, já definimos as datas das próximas aulas, mas você pode alterá-las conforme achar necessário.'
                  }
                  placement="left"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: theme.colors.lowPure,
                        '& .MuiTooltip-arrow': {
                          color: 'common.black',
                        },
                      },
                    },
                  }}
                >
                  <S.DateLabel>{date}</S.DateLabel>
                </S.TooltipLabel>
              ) : (
                <S.DateLabel>{date}</S.DateLabel>
              )}

              <S.DividerVertical orientation="vertical" />
            </>
          )}

          {professor ? (
            <>
              <S.AvatarDefault alt="Foto do Professor" src={professor?.txImagePath} />
              {isMobile && isRemark && <S.TextInfoLabel>{professor?.txName}</S.TextInfoLabel>}
              {!isMobile && !isRemark && <S.TextInfoLabel>{professor?.txName}</S.TextInfoLabel>}
              {isMobile && !isRemark && <S.TextInfoLabel>{professor?.txName}</S.TextInfoLabel>}
            </>
          ) : (
            <>
              <S.AvatarDefault alt="Foto do Professor" src={user?.picture} />
              <S.TextInfoLabel>{user?.name}</S.TextInfoLabel>
            </>
          )}

          {showActions && (
            <>
              <S.ChipCustom>você</S.ChipCustom>
              {!isRemark && (
                <>
                  <UilEditAlt
                    data-testid="trail-edit-button"
                    style={{
                      cursor: isDisabled ? 'not-allowed' : 'pointer',
                    }}
                    size={16}
                    color={isDisabled ? theme.colors.lowLight : theme.colors.actionPure}
                    onClick={!isDisabled ? () => handleButtonClick(nuOrder) : undefined}
                  />
                  {project === 2 && (
                    <UilTimesCircle
                      data-testid="trail-desengage-button"
                      style={{
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                      }}
                      size={16}
                      color={isDisabled ? theme.colors.lowLight : theme.colors.feedback.error.main}
                      onClick={!isDisabled ? () => handleLeave(nuOrder) : undefined}
                    />
                  )}
                </>
              )}
            </>
          )}
        </S.MiniCardContainer>
      </S.ContainerMiniCard>
    )
  }

  if (isLoading)
    return (
      <S.MainBox>
        <S.ListBox>
          <Box width={'100%'} display={'grid'}>
            <SkeletonComponent width={'98%'} height={750} variant={'rounded'} />
          </Box>
        </S.ListBox>
        <S.ListBox>
          <Box width={'100%'} display={'grid'}>
            <SkeletonComponent width={'100%'} height={750} variant={'rounded'} />
          </Box>
        </S.ListBox>
      </S.MainBox>
    )
  const renderDatePicker = (moment: MomentDates, index: number) => {
    const noProfessor = moment.professor && !!moment.idUserProfessor && !!moment.professor.idUser
    switch (true) {
      case !!moment.dtSchedule && moment.coMomentStatus !== 'AUPE':
        return (
          <>
            <MiniCard
              project={moment.project}
              nuOrder={moment?.nuOrder}
              professor={moment?.professor}
              type={moment?.type}
              moment={moment}
              date={dayjs(moment.dtSchedule).format('DD/MM')}
            />
            <DesktopDatePicker
              open={openPicker === moment?.nuOrder}
              onClose={() => setOpenPicker(null)}
              inputFormat="DD/MM"
              value={moment.dtSchedule}
              disablePast={['schedule'].includes(moment?.type)}
              disabled={validateMoment(moment)}
              dayOfWeekFormatter={formatDayOfWeek}
              shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
              onChange={
                ['edit'].includes(moment.type)
                  ? (newDate) => handleDateChange({ index, newDate })
                  : (newDate) => handleDateChangeSchedule(index, newDate)
              }
              renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
            />
          </>
        )

      case (!moment.dtSchedule || !noProfessor) && moment.coMomentStatus !== 'AUPE':
        return (
          <>
            <ButtonComponent
              iconEnd={<UilCalender size={16} />}
              onClick={() => handleButtonClick(moment?.nuOrder)}
              variant="solid"
              fontWeight="normal"
              text="Definir data"
              data-testid="trail-schedule-button"
              fullWidth={isMobile ? true : false}
              size="small2"
            />
            <DesktopDatePicker
              open={openPicker === moment?.nuOrder}
              onClose={() => setOpenPicker(null)}
              inputFormat="DD/MM"
              value={moment.dtSchedule}
              disablePast={['schedule'].includes(moment?.type)}
              dayOfWeekFormatter={formatDayOfWeek}
              shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
              onChange={
                ['edit'].includes(moment.type)
                  ? (newDate) => handleDateChange({ index, newDate })
                  : (newDate) => handleDateChangeSchedule(index, newDate)
              }
              renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
            />
          </>
        )

      case !!moment.dtSchedule && moment.coMomentStatus === 'AUPE':
        if (
          (user?.id_user === moment?.professor?.idUserProfessor ||
            user?.id_user === moment?.professor?.idUser ||
            user?.id_user === moment?.idUserProfessor ||
            user?.id_user === moment?.idUser) &&
          !changedIndexes.includes(index)
        ) {
          return (
            <>
              <S.BtnContainer>
                <ButtonComponent
                  iconEnd={<UilCalender size={16} />}
                  onClick={() => handleButtonClick(moment?.nuOrder)}
                  variant="outline"
                  fontWeight="normal"
                  data-testid="trail-remark-button"
                  text="Remarcar"
                  size="xsmall"
                />
                <DesktopDatePicker
                  open={openPicker === moment?.nuOrder}
                  onClose={() => setOpenPicker(null)}
                  inputFormat="DD/MM"
                  value={moment.dtSchedule}
                  dayOfWeekFormatter={formatDayOfWeek}
                  shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
                  onChange={
                    ['edit'].includes(moment.type)
                      ? (newDate) => handleDateChange({ index, newDate })
                      : (newDate) => handleDateChangeSchedule(index, newDate)
                  }
                  renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
                />
                <ButtonComponent
                  iconEnd={<UilCalender size={16} />}
                  onClick={() => handleRealize(moment?.nuOrder, moment?.dtSchedule)}
                  variant="solid"
                  data-testid="trail-remark-done-button"
                  fontWeight="normal"
                  text="Marcar como Realizada"
                  size="xsmall"
                  fullWidth={isMobile ? true : false}
                />
              </S.BtnContainer>
              <MiniCard
                project={moment.project}
                nuOrder={moment?.nuOrder}
                professor={moment?.professor}
                type={moment?.type}
                moment={moment}
                date={dayjs(moment.dtSchedule).format('DD/MM')}
                isRemark={true}
              />
            </>
          )
        } else {
          return (
            <>
              <MiniCard
                project={moment.project}
                nuOrder={moment?.nuOrder}
                professor={moment?.professor}
                moment={moment}
                type={moment?.type}
                date={dayjs(moment.dtSchedule).format('DD/MM')}
              />
              <DesktopDatePicker
                open={openPicker === moment?.nuOrder}
                onClose={() => setOpenPicker(null)}
                inputFormat="DD/MM"
                value={moment.dtSchedule}
                disablePast={['schedule'].includes(moment?.type)}
                disabled={validateMoment(moment)}
                dayOfWeekFormatter={formatDayOfWeek}
                shouldDisableDate={(date) => disabledDate(date as Dayjs, moment?.nuOrder)}
                onChange={
                  ['edit'].includes(moment.type)
                    ? (newDate) => handleDateChange({ index, newDate })
                    : (newDate) => handleDateChangeSchedule(index, newDate)
                }
                renderInput={({ inputRef, inputProps }) => <div ref={inputRef} {...inputProps} />}
              />
            </>
          )
        }

      default:
        return null
    }
  }

  const handleButtonClick = (nuOrder: number) => {
    setOpenPicker(nuOrder)
  }

  return (
    <>
      <S.Container isView={true} mobile={isMobile}>
        <S.MainBox>
          <S.ListBox isView={true} mobile={isMobile}>
            <S.Title>Defina a data de uma ou mais aulas que você deseja escolher:</S.Title>
            {dates?.map((moment, index) => (
              <>
                <S.BoxBetween>
                  <S.RowContainer key={index} mobile={isMobile}>
                    {isMobile ? (
                      <S.TitleWrapperMobile>
                        <S.NumberBox>{moment?.nuOrder}</S.NumberBox>
                        <S.TitleCardProject>{moment?.txTitle}</S.TitleCardProject>
                      </S.TitleWrapperMobile>
                    ) : (
                      <S.TitleWrapper mobile={isMobile}>
                        <S.WrapperNumber>
                          <S.NumberBox>{moment?.nuOrder}</S.NumberBox>
                        </S.WrapperNumber>
                        <S.TitleCardProject>{moment?.txTitle}</S.TitleCardProject>
                      </S.TitleWrapper>
                    )}

                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                      {renderDatePicker(moment, index)}
                    </LocalizationProvider>
                  </S.RowContainer>
                </S.BoxBetween>
                {index !== dates.length - 1 && <Divider />}
              </>
            ))}
            {!!isMobile && (
              <>
                <S.FullDivider />
                <S.ContentSelectDate>
                  <ButtonComponent
                    text={'Salvar datas da Trilha'}
                    variant="solid"
                    fontWeight="normal"
                    size="large"
                    data-testid="trail-save-button"
                    color={!canSchedule ? 'disabled' : 'action'}
                    disabled={!canSchedule}
                    loading={loadingSchedule}
                    sizeLoading={16}
                    onClick={actionSchedule}
                    iconStart={<UilCheck size={24} />}
                    fullWidth={true}
                  />
                  <S.ContainerBtn>
                    <ButtonComponent
                      text={'Cancelar'}
                      fontColor={'black'}
                      fontSize="large"
                      fontWeight={'normal'}
                      data-testid="trail-back-button"
                      onClick={() => handleBack()}
                      variant="text"
                      size="medium"
                    />
                    {canDesengage && (
                      <ButtonComponent
                        text={'Desocupar Trilha'}
                        fontSize="large"
                        data-testid="trail-desengage-button"
                        fontWeight={'normal'}
                        color="error"
                        variant="outline"
                        onClick={actionDesengage}
                        sizeLoading={16}
                        size="medium"
                      />
                    )}
                  </S.ContainerBtn>
                </S.ContentSelectDate>
              </>
            )}
          </S.ListBox>
          {!isMobile && (
            <S.ListBoxCalendar>
              {datesFormatted && (
                <TimeLineLessonComponent
                  projectType={projectType}
                  dates={datesFormatted}
                  dataTrackSchedule={dataTrackSchedule}
                  handleDateChange={handleDateChangeSchedule}
                  disabledDate={disabledDate}
                />
              )}
            </S.ListBoxCalendar>
          )}
        </S.MainBox>
      </S.Container>
    </>
  )
}

export default Form
