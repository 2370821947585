import { useNavigate } from 'react-router-dom'

import { UilEye } from '@iconscout/react-unicons'
import { Tooltip } from '@mui/material'
import { gaEvents } from '~/events'

import { AvatarComponent, ButtonComponent } from '~/components'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'
import useIsMobile from '~/validations/isMobile'
import { renderLabelBtn, renderStatusText } from '~/validations/renderStatusText'
import { statusColor } from '~/validations/statusColorsEM'
import { statusIcon } from '~/validations/statusIcon'
import { truncateText } from '~/validations/truncateText'

import { ICardDetailTrailProps } from './CardDetailTrail.interface'
import * as S from './CardDetailTrail.styles'

export default function CardDetailTrail({
  index,
  dataTrail,
  handleActiveCard,
  isTrailGenerated,
  isProjectTrailNotgenereated,
  idClass,
  moment,
  variant,
  itemsPerPage,
}: ICardDetailTrailProps) {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const handleGoToProject = () => {
    navigate(`/class/${idClass}/project/${moment?.idMoment}`, { state: { from: location.pathname } })
  }
  const idUserProfessor = moment?.professor?.idUserProfessor
  const userInfo = JSON.parse(localStorage?.getItem('@LEKTO:user') as string)
  const isSameUser = idUserProfessor === userInfo.id_user
  return (
    <S.CardSliderResumeContent
      bgColor={statusColor(moment?.coMomentStatus ?? '').bgColor}
      isTrailGenerated={isTrailGenerated || false}
    >
      <S.CardHeader>
        {!isTrailGenerated ? (
          <S.ContentNumber>
            <S.StepNumber>{dataTrail?.lessonTrackGroupNuOrder ?? dataTrail?.nuOrder}</S.StepNumber>
          </S.ContentNumber>
        ) : (
          <>
            <S.ContainerTime>
              <S.CardIndex color={statusColor(moment?.coMomentStatus ?? '').indexColor}>
                {dataTrail?.lessonTrackGroupNuOrder ?? dataTrail?.nuOrder}
              </S.CardIndex>
              <S.CardTime color={statusColor(moment?.coMomentStatus ?? '').textColor}>
                {getFormattedLabelWithDay(moment?.dtSchedule ?? '')}
              </S.CardTime>
            </S.ContainerTime>

            <S.CardStatus color={statusColor(moment?.coMomentStatus ?? '').textColor}>
              <S.TextSpan>{statusIcon(moment?.coMomentStatus ?? '')}</S.TextSpan>
              <p>{renderStatusText(moment?.coMomentStatus ?? '')}</p>
            </S.CardStatus>
          </>
        )}
      </S.CardHeader>
      <Tooltip title={dataTrail?.txTitle} placement="top">
        <S.TitleCard>{truncateText(dataTrail?.txTitle, 35)}</S.TitleCard>
      </Tooltip>
      {!isTrailGenerated && dataTrail && (
        <>
          {dataTrail?.txDescription?.length > 65 ? (
            <Tooltip title={dataTrail?.txDescription} placement="top">
              <S.TitleLessonCard>{dataTrail?.txDescription.slice(0, 65) + '...'}</S.TitleLessonCard>
            </Tooltip>
          ) : (
            <>
              <S.TitleLessonCard>{dataTrail?.txDescription}</S.TitleLessonCard>
            </>
          )}
        </>
      )}
      {isTrailGenerated && (
        <S.ProfessorContent>
          <S.ConteinerProfessor>
            <AvatarComponent
              key={moment?.professor?.txName}
              label={moment?.professor?.txName}
              photoUrl={moment?.professor?.txImagePath}
              size="xsmall"
            />
            {isMobile ? (
              <>
                <Tooltip title={moment?.professor?.txName}>
                  <S.TextInfoLabel>{moment?.professor?.txName}</S.TextInfoLabel>
                </Tooltip>
                <S.TextInfoLabel>{isSameUser && <span> - você</span>}</S.TextInfoLabel>
              </>
            ) : (
              <>
                {moment?.professor?.txName ? (
                  <S.TextInfoLabel>
                    {moment?.professor?.txName}
                    {isSameUser && <span> - você</span>}
                  </S.TextInfoLabel>
                ) : (
                  <S.TextInfoLabel>Sem professor</S.TextInfoLabel>
                )}
              </>
            )}
          </S.ConteinerProfessor>
        </S.ProfessorContent>
      )}
      {!isTrailGenerated ? (
        <S.BtnExplorar
          onClick={() => {
            handleActiveCard(index)
          }}
        >
          {' '}
          Explorar {variant === 'P2' ? 'Etapa' : 'Aula'}
          <UilEye size={16} />
        </S.BtnExplorar>
      ) : (
        <ButtonComponent
          variant={moment?.coMomentStatus === 'PEND' ? 'solid' : 'outline'}
          size={'xsmall'}
          fontWeight="normal"
          title={
            moment?.coMomentStatus === 'AVPE' || moment?.coMomentStatus === 'PEND' || moment?.coMomentStatus === 'AUPE'
              ? renderLabelBtn(moment?.coMomentStatus ?? '', true)
              : 'Explorar etapa'
          }
          text={
            moment?.coMomentStatus === 'AVPE' || moment?.coMomentStatus === 'PEND' || moment?.coMomentStatus === 'AUPE'
              ? renderLabelBtn(moment?.coMomentStatus ?? '', true)
              : 'Explorar etapa'
          }
          onClick={() => {
            handleGoToProject()
            gaEvents.eventAccessLessonButton()
          }}
          iconEnd={<UilEye size={16} />}
        />
      )}
    </S.CardSliderResumeContent>
  )
}
