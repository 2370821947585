import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  :root {
    /* Primary Colors */
    --brand-color-primary-pure: #8C22BC;
    --brand-color-primary-light: #F7E5FF;
    --brand-color-primary-medium: #E4A7FF;
    --brand-color-primary-dark: #410B3C;

    /* Quaternary Colors */
    --brand-color-quaternary-pure: #E14983;
    --brand-color-quaternary-light: #FFEEF5;
    --brand-color-quaternary-medium: #FF90BB;
    --brand-color-quaternary-dark: #97003A;

    /* Neutral Colors - Low */
    --neutral-color-low-pure: #000000;
    --neutral-color-low-light: #A3A3A3;
    --neutral-color-low-medium: #666666;
    --neutral-color-low-dark: #292929;

    /* Neutral Colors - High */
    --neutral-color-high-pure: #FFFFFF;
    --neutral-color-high-light: #FAFAFA;
    --neutral-color-high-medium: #E0E0E0;
    --neutral-color-high-dark: #CCCCCC;

    /* Neutral Colors - Mid */
    --neutral-color-mid-pure: #BDC3CF;
    --neutral-color-mid-light: #F4F7FE;
    --neutral-color-mid-medium: #E2E5EC;
    --neutral-color-mid-dark: #7C8189;

    /* Feedback Colors - Accent */
    --feedback-color-accent-pure: #0095FF;
    --feedback-color-accent-light: #E8F6FF;
    --feedback-color-accent-medium: #90D1FF;
    --feedback-color-accent-dark: #005B9C;

    /* Feedback Colors - Success */
    --feedback-color-success-pure: #29CC5F;
    --feedback-color-success-light: #E1FFEB;
    --feedback-color-success-medium: #8CFFB2;
    --feedback-color-success-dark: #007025;

    /* Feedback Colors - Warning */
    --feedback-color-warning-pure: #FF8A00;
    --feedback-color-warning-light: #FFF1E1;
    --feedback-color-warning-medium: #FFC989;
    --feedback-color-warning-dark: #6A3900;

    /* Feedback Colors - Error */
    --feedback-color-error-pure: #D44333;
    --feedback-color-error-light: #FFF7F5;
    --feedback-color-error-medium: #F27B6C;
    --feedback-color-error-dark: #5A0A00;

    /* Competências - Conhecimento */
    --competencias-color-conhecimento-pure: #6096E8;
    --competencias-color-conhecimento-light: #E7EFFC;

    /* Competências - Autoconhecimento e Autocuidado */
    --competencias-color-autocuidado-pure: #16CCD8;
    --competencias-color-autocuidado-light: #DCF7F9;

    /* Competências - Empatia e Cooperação */
    --competencias-color-empatia-pure: #8553F0;
    --competencias-color-empatia-light: #EDE5FD;

    /* Competências - Responsabilidade e Cidadania */
    --competencias-color-cidadania-pure: #0007A0;
    --competencias-color-cidadania-light: #D9DAF1;

    /* Competências - Comunicação, Argumentação e Cultura Digital */
    --competencias-color-comunicacao-pure: #E14983;
    --competencias-color-comunicacao-light: #FBE4EC;

    /* Competências - Pensamento Crítico, Criativo e Rep. Cultural */
    --competencias-color-pensamento-pure: #FFA800;
    --competencias-color-pensamento-light: #FFF2D9;

    /* Competências - Projeto de Vida e Trabalho */
    --competencias-color-projeto-pure: #52D990;
    --competencias-color-projeto-light: #E5F9EE;

    /* Font Weights */
    --font-weight-black: 900;
    --font-weight-extrabold: 800;
    --font-weight-bold: 700;
    --font-weight-semibold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: 400;
    --font-weight-light: 300;
    --font-weight-extralight: 200;
    --font-weight-thin: 100;

    /* Font Sizes */
    --font-size-giant: 64px;
    --font-size-display: 52px;
    --font-size-xxxl: 40px;
    --font-size-xxl: 32px;
    --font-size-xl: 24px;
    --font-size-lg: 20px;
    --font-size-md: 16px;
    --font-size-sm: 14px;
    --font-size-xs: 12px;
    --font-size-xxs: 10px;
    --font-size-xxxs: 8px;

    /* Line Heights */
    --line-height-default: 100%;
    --line-height-sm: 120%;
    --line-height-lg: 150%;
    --line-height-xl: 170%;
    --line-height-xxl: 200%;

    /* Border Radius */
    --border-radius-none: 0px;
    --border-radius-xs: 4px;
    --border-radius-sm: 8px;
    --border-radius-md: 16px;
    --border-radius-lg: 24px;
    --border-radius-pill: 500px;
    --border-radius-circular: 50%;

    /* Border Width */
    --border-width-none: 0px;
    --border-width-hairline: 1px;
    --border-width-thin: 2px;
    --border-width-thick: 4px;
    --border-width-heavy: 8px;

    /* Opacity Levels */
    --opacity-level-semiopaque: 0.80;
    --opacity-level-intense: 0.64;
    --opacity-level-medium: 0.32;
    --opacity-level-light: 0.16;
    --opacity-level-semitransparent: 0.08;

    /* Shadows */
    --shadow-level-1: 0 1px 2px rgba(0, 0, 0, 0.32);
    --shadow-level-2: 0 2px 4px rgba(0, 0, 0, 0.4);
    --shadow-level-3: 0 4px 8px rgba(0, 0, 0, 0.08);
    --shadow-level-4: 0 8px 24px rgba(0, 0, 0, 0.16);
    --shadow-level-5: 0 16px 32px rgba(0, 0, 0, 0.16);
    --shadow-level-6: 0 16px 48px rgba(0, 0, 0, 0.24);

    /* Spacing */
    --spacing-quarck: 4px;
    --spacing-nano: 8px;
    --spacing-xxxs: 16px;
    --spacing-xxs: 24px;
    --spacing-xs: 32px;
    --spacing-sm: 40px;
    --spacing-md: 48px;
    --spacing-lg: 56px;
    --spacing-xl: 64px;
    --spacing-xxl: 80px;
    --spacing-xxxl: 120px;
    --spacing-huge: 160px;
    --spacing-giant: 200px;

    /* Spacing Inset */
    --spacing-inset-quarck: 4px;
    --spacing-inset-nano: 8px;
    --spacing-inset-xs: 16px;
    --spacing-inset-sm: 24px;
    --spacing-inset-md: 32px;
    --spacing-inset-lg: 40px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @font-face {
    font-family: 'unicons-line';
    src: url("../../src/fonts/unicons-line.eot") format('embedded-opentype');
    src: url('../../src/fonts/unicons-line.eot') format('embedded-opentype'), url('../../src/fonts/unicons-line.woff2') format('woff2'),
    url("../../src/fonts/unicons-line.woff") format('woff'), url('../../src/fonts/unicons-line.ttf') format('truetype'),
    url('../../src/fonts/unicons-line.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  body {
    background-color: ${({ theme }) => theme.colors.midLight};
    font-family: 'Inter', sans-serif !important;
    color: ${({ theme }) => theme.colors.lowDark};
  }

  .MuiTypography-root {
    font-family: 'Inter', sans-serif !important;
  }

  .alert-refresh {
    position: fixed;
    bottom: 0;
    padding: 15px 0;
    cursor: pointer;
    width: 100%;
    border: 0;
    box-shadow: none;
    text-align: center;
    z-index: 9999;
    color: white;
    background-color: #8C22BC;
    @media (max-width: 700px) {
      top: 0;
      bottom: unset;
    }
  }

  .ScrollApp {
    overflow-y: auto;
    height: 100vh;
  }

  .styled-scroll {
    ::-webkit-scrollbar {
      height: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 4px;
    }
  }

  .styled-scroll-s {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 4px;
    }
  }

  .styled-scroll-xs {
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #CCCCCC;
      border: 1px solid #CCCCCC;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
      width: 2px;
    }
  }
`
