import React from 'react'

import { ITruncatedTextDescriptionProps } from './TruncatedTextDescription.interfaces'
import * as S from './TruncatedTextDescription.styles'

export default function TruncatedTextDescription({
  title,
  txDescription,
  isMobile,
  textLength,
}: ITruncatedTextDescriptionProps) {
  const [verTexto, setVerTexto] = React.useState(false)
  function handleVerTexto() {
    setVerTexto(!verTexto)
  }
  const truncatedTextTrail =
    txDescription.length > (isMobile ? 148 : textLength)
      ? `${txDescription?.substring(0, isMobile ? 148 : textLength)}... `
      : txDescription
  return (
    <S.CardDescription>
      <S.Description>
        <S.AboutDescription>Sobre a {title}: </S.AboutDescription>
        {verTexto ? txDescription : truncatedTextTrail}
        {txDescription.length > (isMobile ? 148 : 497) && (
          <S.BtnAction onClick={() => handleVerTexto()}>{verTexto ? ' Ver menos' : ' Ver mais'}</S.BtnAction>
        )}
      </S.Description>
    </S.CardDescription>
  )
}
