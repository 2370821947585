import { Box, Typography } from '@mui/material'
import { styled as styledMUI } from '@mui/material/styles'

import theme from '~/styles/theme'

import { Span } from './../../../containers/private/Fund2/Trails/Header/Header.styles'

export const Container = styledMUI(Box)<{ isNotification: boolean | undefined }>(({ isNotification }) => ({
  minHeight: '152px',
  minWidth: '350px',
  maxWidth: isNotification ? '524px' : '450px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '32px',
  gap: '10px',
}))

export const SmallView = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '448px',
}))

export const ContentIcon = styledMUI(Box)(() => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#E8F6FF',
  borderRadius: '50px',
}))

export const BtnClose = styledMUI(Box)<{ isNotification?: boolean | undefined }>(({ isNotification }) => ({
  position: 'relative',
  left: isNotification ? '185px' : '155px',
  top: '-20px',
  color: '#000',
  cursor: 'pointer',
  padding: 0,
}))

export const ContentIconFull = styledMUI(Box)(({ color }) => ({
  height: '64px',
  width: '64px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: `${color}`,
  borderRadius: '50px',
}))

export const Title = styledMUI(Typography)(() => ({
  textAlign: 'center',
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: '700',
  fontSize: '20px',
  lineHeight: '150%',
  color: '#000',
}))

export const Description = styledMUI(Typography)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  color: '#000',
}))

export const ContentButtons = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  flexDirection: 'column',
  '@media (max-width: 700px)': {
    flexDirection: 'column-reverse',
  },
}))

export const ContentInfoBox = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '12px 8px 12px 8px',
  borderRadius: '8px',
  height: '72px',
  width: '302px',
  border: '2px solid #BDC3CF',

  '@media (max-width: 700px)': {
    flexDirection: 'column-reverse',
  },
}))

export const ContentInfoTitle = styledMUI(Span)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  paddingBottom: '8px',
}))

export const TextInfoWarn = styledMUI(Span)(() => ({
  fontStyle: 'normal',
  fontFamily: 'Inter',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'center',
  padding: '8px 4px',
  borderRadius: '4px',
}))

export const ContentInfoWarn = styledMUI(Box)(() => ({
  display: 'flex',
  gap: '10px',
  borderRadius: '8px',
  border: 'none',
}))

export const ContainerBox = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '6px',
  width: '100%',
  marginTop: '12px',
  marginBottom: '12px',
}))

export const ContenBox = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '8px',
}))

export const TitleWrapper = styledMUI(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '50%',
  height: '100%',
  alignSelf: 'stretch',
  flex: '1 0 0',
}))

export const WrapperNumber = styledMUI(Box)(() => ({
  marginLeft: '0px',
  display: 'flex',
  alignItems: 'center',
}))

export const NumberBox = styledMUI(Box)(() => ({
  justifySelf: 'center',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '16px',
  alignItems: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  backgroundColor: theme.colors.midPure,
  color: 'white',
  '@media (max-width: 600px)': {
    width: '24px',
    height: '24px',
    padding: 10,
  },
}))

export const TitleCardProject = styledMUI(Typography)(() => ({
  color: theme.colors.lowPure,
  fontSize: theme.typography.headings.headingSmall.fontSize,
  fontWeight: theme.typography.headings.headingSmall.fontWeight,
  lineHeight: theme.typography.headings.headingLarge.lineHeight,
  maxWidth: '210px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  whiteSpace: 'normal',
}))

export const ContainerCardNotification = styledMUI(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '176px',
  maxHeight: '376px',
  width: '100%',
  gap: '24px',
  overflowY: 'auto',
}))
