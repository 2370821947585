import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { UilArrowDown, UilArrowUp } from '@iconscout/react-unicons'
import { CircularProgress, debounce } from '@mui/material'
import dayjs from 'dayjs'

import DayCell from './DayCell'
import * as S from './TimeLesson.styles'

const daysOfWeek = ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab']

export const TimeLineLessonComponent = ({
  dates,
  handleDateChange,
  disabledDate,
  dataTrackSchedule,
  projectType,
}: {
  dates: { dtSchedule: string; disabled: boolean }[]
  handleDateChange: (index: number, newDate: string) => void
  disabledDate: (date: dayjs.Dayjs, order: number) => boolean
  open: boolean
}) => {
  const { trackId } = useParams()
  const [datesState, setDatesState] = useState<{ dtSchedule: string; disabled: boolean; order: number }[]>([])
  const [startDate, setStartDate] = useState('')
  const tableRef = useRef<HTMLDivElement>(null)
  const [visibleMonths, setVisibleMonths] = useState<dayjs.Dayjs[]>([])
  const [currentMonthName, setCurrentMonthName] = useState(dayjs(visibleMonths[0]).format('MMMM YYYY'))
  const [isContentReady, setContentReady] = useState(false)
  const [loadedCellsCount, setLoadedCellsCount] = useState(0)
  const [scrollSet, setScrollSet] = useState(false)
  const otherClassTracks = dataTrackSchedule?.otherClassTracks

  const [canLoadMorePast, setCanLoadMorePast] = useState(false)
  const [canLoadMoreFuture, setCanLoadMoreFuture] = useState(false)

  const handleCellReady = useCallback(() => {
    setLoadedCellsCount((count) => count + 1)
  }, [])

  function capitalizeFirstLetter(inputString: string): string {
    const words = inputString.split(' ')

    if (words.length <= 1) {
      return inputString
    }

    const firstWord = words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase()
    const lastWord = words[words.length - 1].charAt(0).toUpperCase() + words[words.length - 1].slice(1).toLowerCase()

    const result = `${firstWord} ${lastWord}`

    return result
  }

  useEffect(() => {
    setDatesState(dates?.map((date, index) => ({ dtSchedule: date.dtSchedule, disabled: date.disabled, order: index })))

    setStartDate(dayjs(dates[0] && dates[0].dtSchedule !== '' ? dates[0].dtSchedule : dayjs()).format('YYYY-MM-DD'))
  }, [dates])

  useEffect(() => {
    const currentMonth = dayjs().startOf('month')
    const monthsBefore = Array.from({ length: 3 }, (_, i) => currentMonth.clone().subtract(i + 1, 'month')).reverse()
    const monthsAfter = Array.from({ length: 3 }, (_, i) => currentMonth.clone().add(i + 1, 'month'))
    setVisibleMonths([...monthsBefore, currentMonth, ...monthsAfter])
  }, [dates])

  useLayoutEffect(() => {
    if (tableRef.current && dates.length && !scrollSet && isContentReady) {
      const startDayCell = tableRef.current.querySelector(`td[data-date='${startDate}']`) as HTMLElement

      if (startDayCell) {
        const topPosition = startDayCell.offsetTop
        const cellHeight = startDayCell.clientHeight

        tableRef.current.scrollTop = topPosition - tableRef.current.clientHeight / 2 + cellHeight / 2
        setScrollSet(true)
      }
    }
  }, [visibleMonths, dates.length, startDate, scrollSet, isContentReady])
  const generateWeeks = (startMonth: dayjs.Dayjs) => {
    const weeks = []
    let currentDay = startMonth.clone().startOf('week')
    const endOfMonth = startMonth.clone().endOf('month')

    while (currentDay.isBefore(endOfMonth, 'day') || currentDay.isSame(endOfMonth, 'day')) {
      const week = []
      for (let i = 0; i < 7; i++) {
        const day = currentDay.clone()
        week.push(day)
        currentDay = currentDay.add(1, 'day')
      }

      const isFirstDayOfWeek = week[0].day() === startMonth.day()
      const isFirstDayOfNextMonth =
        (isFirstDayOfWeek && !week[0].isSame(startMonth, 'month')) || (isFirstDayOfWeek && week[0].date() === 1)

      if (week[0].month() === startMonth.month() || isFirstDayOfNextMonth) {
        weeks.push(week)
      }
    }

    return weeks
  }

  const totalCells = visibleMonths.reduce((acc, month) => acc + generateWeeks(month).length * 7, 0)

  useEffect(() => {
    if (loadedCellsCount >= totalCells && totalCells > 0) {
      setContentReady(true)
    }
  }, [loadedCellsCount, totalCells])

  const loadMoreMonths = (direction: 'future' | 'past') => {
    const monthsToAdd = 1
    const updatedMonths = [...visibleMonths]
    if (direction === 'past' && canLoadMorePast) {
      const earliestMonth = visibleMonths[0].subtract(monthsToAdd, 'month')
      updatedMonths.unshift(earliestMonth)
      setCanLoadMorePast(dayjs(earliestMonth).isAfter(dayjs().subtract(1, 'year'), 'month'))
      if (tableRef && tableRef.current) {
        tableRef.current.scrollTop = 0
      }
    } else if (direction === 'future' && canLoadMoreFuture) {
      const latestMonth = visibleMonths[visibleMonths.length - 1].add(monthsToAdd, 'month')
      updatedMonths.push(latestMonth)
      setCanLoadMoreFuture(dayjs(latestMonth).isBefore(dayjs().add(1, 'year'), 'month'))
      if (tableRef && tableRef.current) {
        tableRef.current.scrollTop = tableRef.current.scrollHeight
      }
    }
    setVisibleMonths(updatedMonths)
  }

  const debouncedHandleScroll = useRef(
    debounce(() => {
      if (tableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = tableRef.current
        if (scrollTop + clientHeight + 5 >= scrollHeight) {
          setCanLoadMoreFuture(true)
          setCanLoadMorePast(false)
        } else if (scrollTop === 0) {
          setCanLoadMorePast(true)
          setCanLoadMoreFuture(false)
        } else {
          setCanLoadMorePast(false)
          setCanLoadMoreFuture(false)
        }

        const approximateIndex = Math.floor((scrollTop / (scrollHeight - clientHeight)) * visibleMonths.length)
        const index = Math.min(visibleMonths.length - 1, Math.max(0, approximateIndex))
        const firstVisibleMonth = dayjs(visibleMonths[index])

        setCurrentMonthName(firstVisibleMonth.locale('pt-br').format('MMMM YYYY'))
      }
    }, 200),
  )

  const handleScroll = useCallback(() => {
    debouncedHandleScroll.current()
  }, [debouncedHandleScroll])

  const handleDrop = useCallback(
    (lessonIndex: number, newDay: dayjs.Dayjs) => {
      const newDayFormatted = newDay.format('YYYY-MM-DD')
      handleDateChange(lessonIndex, newDayFormatted)
    },
    [handleDateChange],
  )

  return (
    <S.Container>
      <S.Row>
        <S.TitleYear>{capitalizeFirstLetter(currentMonthName)}</S.TitleYear>
        <S.Title>Cronograma da trilha</S.Title>
      </S.Row>
      {canLoadMorePast && (
        <S.ButtonLoadContainer>
          <S.ButtonLoadMore onClick={() => loadMoreMonths('past')}>
            Carregar meses anteriores <UilArrowUp color={'#0095FF'} />
          </S.ButtonLoadMore>
        </S.ButtonLoadContainer>
      )}
      <S.ContainerTable
        className={'styled-scroll-s'}
        isContentReady={isContentReady}
        onScrollCapture={handleScroll}
        ref={tableRef}
      >
        <S.Table>
          <thead>
            <tr>
              {daysOfWeek.map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {otherClassTracks &&
              visibleMonths.map((month, index) => (
                <React.Fragment key={month.format('YYYY-MM')}>
                  {generateWeeks(month).map((week, weekIndex) => (
                    <tr key={`${month.format('YYYY-MM')}-${weekIndex}`}>
                      {week.map((day, dayIndex) => {
                        const isFirstDayOfWeek = dayIndex === 0
                        const containsDifferentMonths = week.some((d) => !d.isSame(month, 'month'))
                        const isFirstDayOfNextMonth = isFirstDayOfWeek && (day.date() === 1 || containsDifferentMonths)

                        return (
                          <DayCell
                            key={dayIndex}
                            dates={datesState}
                            day={day}
                            otherClassTracks={otherClassTracks}
                            idTrack={Number(trackId)}
                            dayIndex={dayIndex}
                            projectType={projectType}
                            month={month}
                            onReady={handleCellReady}
                            handleDrop={handleDrop}
                            disabledDate={disabledDate}
                            visibleMonth={capitalizeFirstLetter(
                              visibleMonths[index].locale('pt-br').format('MMMM YYYY'),
                            )}
                            visibleMonthPlus={capitalizeFirstLetter(
                              visibleMonths[index].add(1, 'month').locale('pt-br').format('MMMM YYYY'),
                            )}
                            containsDifferentMonths={containsDifferentMonths}
                            isFirstDayOfNextMonth={isFirstDayOfNextMonth}
                          />
                        )
                      })}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
          </tbody>
        </S.Table>
      </S.ContainerTable>
      {canLoadMoreFuture && (
        <S.ButtonLoadFutureContainer>
          <S.ButtonLoadMore onClick={() => loadMoreMonths('future')}>
            Carregar meses futuros <UilArrowDown color={'#0095FF'} />
          </S.ButtonLoadMore>
        </S.ButtonLoadFutureContainer>
      )}
      {!isContentReady && (
        <S.ContainerLoading>
          <div>
            <S.TitleLoading>Carregando visualização...</S.TitleLoading>
            <CircularProgress />
          </div>
        </S.ContainerLoading>
      )}
    </S.Container>
  )
}
