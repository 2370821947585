import { UilCalender, UilArrowRight } from '@iconscout/react-unicons'

import { IAvailableClassesToSchedule } from '~/services/TrailSchedule/Trail.interfaces'

import { ButtonComponent } from '~/components'
import TagClass from '~/components/Cards/TagClass/TagClass'
import { ModalComponentBase } from '~/components/Modals/ModalBase/Modal'
import { IModalClassesAvailableToScheduleProps } from '~/components/Modals/ModalClassesAvailableToSchedule/ModalClassesAvailableToSchedule.interface'

import { translateCoStage } from '~/validations/translateCoStage'

import * as S from './ModalClassesAvailableToSchedule.styles'

export function ModalClassesAvailableToSchedule({
  openAvailableClassesToScheduleModal,
  data,
  actionScheduleLater,
  handleCloseAvailableClassesToScheduleModal,
  handleNavigateToSchedule,
}: IModalClassesAvailableToScheduleProps) {
  return (
    <ModalComponentBase
      width={'fit-content'}
      maxWidth={'524px'}
      open={openAvailableClassesToScheduleModal}
      actionCloseModal={handleCloseAvailableClassesToScheduleModal}
    >
      <S.ModalClassesAvailableContainer>
        <S.ModalTitle>
          Esta trilha também pode ser escolhida para outras turmas. Aproveite e já faça o agendamento. É prático e
          rápido!
        </S.ModalTitle>

        <S.ContentContainer>
          {data &&
            data.map((item: IAvailableClassesToSchedule) => (
              <S.ClassesCardContainer key={item.idClass}>
                <S.ClassesTitleSection>
                  <span>
                    <strong>{item.txGrade}</strong> - {item.txClassName}
                  </span>
                  <TagClass texts={[translateCoStage(item.coStage), item.txClassShift]} variant="default" />
                </S.ClassesTitleSection>
                <ButtonComponent
                  variant={'outline'}
                  size={'small2'}
                  text={'Definir datas'}
                  fontWeight={'normal'}
                  iconEnd={<UilCalender size={16} />}
                  onClick={() => handleNavigateToSchedule(item.idClass)}
                  data-test-id="button-schedule-other-class"
                />
              </S.ClassesCardContainer>
            ))}
        </S.ContentContainer>

        <ButtonComponent
          variant={'solid'}
          size={'large'}
          text={'Fazer isso depois'}
          iconEnd={<UilArrowRight size={24} />}
          onClick={actionScheduleLater}
          data-test-id="button-schedule-later"
        />
      </S.ModalClassesAvailableContainer>
    </ModalComponentBase>
  )
}
