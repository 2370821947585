import ReactGA from 'react-ga4'

import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import jwt_decode from 'jwt-decode'
import { ThemeProvider } from 'styled-components'
import userflow from 'userflow.js'

import { AppBarProvider } from '~/_context/AppBar'
import { AuthProvider } from '~/_context/Auth'
import { IUserProps } from '~/_context/Auth/Auth.interfaces'
import { OfflineProvider } from '~/_context/Offline'
import { SnackbarProvider } from '~/_context/Snackbar'

import { initSentry } from '~/services/Sentry'
import { isSentryEnabled, updateUnleashContext } from '~/services/Unleash'

import { ReloadPrompt, SnackbarComponent } from '~/components'

import { Router } from '~/routes'

import { GlobalStyles } from '~/styles/global'
import theme from '~/styles/theme'

import packageJson from '../package.json'
import { ToastProvider } from './_context/Toast'

ReactGA.initialize('G-LZ1CN2YT2M')
userflow.init('ct_kd74zoogbzfx7nxwqbs3fxbd6a')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

declare global {
  interface Window {
    Userflow: {
      init: (key: string) => void
    }
  }
}

const App = () => {
  if (isSentryEnabled()) {
    initSentry()
  }

  const token = localStorage.getItem('@LEKTO:token')

  if (token) {
    const decodedUser: IUserProps = jwt_decode(token)
    Sentry.setUser({ id: decodedUser.id_user.toString(), email: decodedUser.email, username: decodedUser.name })
    updateUnleashContext({
      userId: decodedUser.email,
      properties: {
        idUser: decodedUser.id_user.toString(),
        version: packageJson.version,
      },
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <OfflineProvider>
          <QueryClientProvider client={queryClient}>
            <GlobalStyles />
            <ReloadPrompt />

            <SnackbarProvider>
              <ToastProvider>
                <AppBarProvider>
                  <Router />
                </AppBarProvider>
              </ToastProvider>

              <SnackbarComponent />
            </SnackbarProvider>
          </QueryClientProvider>
        </OfflineProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default Sentry.withProfiler(App, { name: 'Plataform-Lekto' })
