import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { UilArrowRight, UilFileBookmarkAlt, UilCheckCircle } from '@iconscout/react-unicons'
import { HubConnection } from '@microsoft/signalr'
import { gaEvents } from '~/events'

import { useGetNotifications, useMarkAsRead, useServiceMarkAllRead } from '~/services/Notifications'
import { createSignalRConnection } from '~/services/SignalR/SignalR'

import { DividerComponent, ModalActionComponent } from '~/components'

import { truncateText } from '~/validations/truncateText'

import { INotification, INotificationsProps } from './NotificationsMenu.interface'
import * as S from './NotificationsMenu.styles'

export default function NotificationsMenu({
  anchorElNotificationsMenu,
  setAnchorElNotificationsMenu,
  setExisteNotifications,
}: INotificationsProps) {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const [connection, setConnection] = useState<HubConnection | null>(null)
  const [openModalNotification, setOpenModalNotification] = useState(false)
  const navigate = useNavigate()
  const totalCount = notifications.filter((notification) => !notification.isRead).length
  const modalStorage = sessionStorage.getItem('@LEKTO:notificationModal')
  const showModal = modalStorage === 'true'
  const path = location.pathname
  const {
    isLoading: isLoadingNotifications,
    data: dataNotifications,
    refetch: refetchDataNotifications,
    remove,
  } = useGetNotifications()

  const { isLoading: isLoadingMarkAllRead, data: dataMarkRead, mutate: actionMarkAll } = useServiceMarkAllRead()
  const { isLoading: isLoadingMarkSingle, data: dataMarkSingle, mutate: actionMarkASingle } = useMarkAsRead()

  useEffect(() => {
    if (connection) return

    const connectToHub = async () => {
      const hubConnection = createSignalRConnection()

      try {
        await hubConnection.start()
        hubConnection.on('ReceiveNotifications', (message) => {
          setNotifications((prevMessages) => {
            const notificationExists = prevMessages.some(
              (notification) => notification.idNotification === message.idNotification,
            )
            if (!notificationExists) {
              return [message, ...prevMessages]
            }
            return prevMessages
          })
        })
        setConnection(hubConnection)
      } catch (err) {
        // Lidar com erros, se necessário
      }
    }

    connectToHub()

    return () => {
      if (connection) {
        connection.stop()
      }
    }
  }, [connection])

  useEffect(() => {
    if (!isLoadingNotifications) {
      if (dataNotifications && dataNotifications.length > 0) {
        setNotifications(dataNotifications)

        if (dataNotifications.find((notification: INotification) => !notification.isRead) && showModal) {
          setOpenModalNotification(true)
        }
        if (path.includes('/schedule') && path.includes('/track')) {
          setOpenModalNotification(false)
        }
      }
    }
  }, [dataNotifications, isLoadingNotifications, path, showModal])

  const handleCloseNotificationsMenu = () => {
    if (setAnchorElNotificationsMenu) {
      setAnchorElNotificationsMenu(null)
    }
  }

  const handleMarkAllReadAction = () => {
    actionMarkAll()
    refetchDataNotifications().then()
    handleCloseNotificationsMenu()
  }

  const handleMarkAsReadAction = (notification: INotification) => {
    actionMarkASingle(notification.idNotification)
    refetchDataNotifications().then()
  }

  const handleNavigate = (notification: INotification) => {
    if (!!notification?.payload?.idClass && !!notification?.payload?.idTrack) {
      navigate(`/schedule/${notification?.payload?.idClass}/track/${notification?.payload?.idTrack}`)
    }
    gaEvents.eventSelectNewTrailButton()
    setOpenModalNotification(false)
  }

  const handleConfirmAll = () => {
    sessionStorage.setItem('@LEKTO:notificationModal', 'false')
    actionMarkAll()
    refetchDataNotifications().then()
  }

  const handleConfirmNewDate = (itemNotification: INotification) => {
    handleMarkAsReadAction(itemNotification)
  }

  const handleRemarkNewDate = (itemNotification: INotification) => {
    handleMarkAsReadAction(itemNotification)
    handleNavigate(itemNotification)
  }

  const handleCloseModal = () => {
    sessionStorage.setItem('@LEKTO:notificationModal', 'false')
    setOpenModalNotification(false)
  }

  useEffect(() => {
    if (!isLoadingMarkAllRead && dataMarkRead) {
      toast.success('Todas as novas datas foram aceitas com sucesso!', {
        icon: <UilCheckCircle color="#fff" size="24" />,
        style: {
          background: '#0BB07B',
          color: '#fff',
        },
      })
      setOpenModalNotification(false)
      refetchDataNotifications()
    }
  }, [dataMarkRead, isLoadingMarkAllRead, refetchDataNotifications, remove, setExisteNotifications])

  useEffect(() => {
    if (!isLoadingMarkSingle && dataMarkSingle) {
      refetchDataNotifications()
      if (totalCount === 0) {
        setOpenModalNotification(false)
      }
    }
  }, [dataMarkSingle, isLoadingMarkSingle, refetchDataNotifications, setExisteNotifications, totalCount])

  useEffect(() => {
    if (totalCount > 0) {
      setExisteNotifications(totalCount)
    } else {
      setExisteNotifications(0)
      setOpenModalNotification(false)
    }
  }, [setExisteNotifications, totalCount])

  return (
    <>
      {isLoadingNotifications ? (
        <p>Carregando...</p>
      ) : (
        <>
          <S.MenuContainer
            anchorEl={anchorElNotificationsMenu}
            open={Boolean(anchorElNotificationsMenu)}
            onClose={handleCloseNotificationsMenu}
          >
            <S.ContentNotifications>
              <S.HeaderContainer>
                <S.UserFullName>Notificações</S.UserFullName>
                {notifications?.length !== 0 && (
                  <S.ActionLidas active={totalCount > 0} onClick={totalCount > 0 ? handleMarkAllReadAction : undefined}>
                    Marcar todas como lidas
                  </S.ActionLidas>
                )}
              </S.HeaderContainer>
              <S.WrapperDivider>
                <DividerComponent />
              </S.WrapperDivider>
              {notifications?.find((notification) => !notification.isRead) && (
                <S.InfoAlert severity="info">
                  {notifications?.length === 1
                    ? 'Existe uma nova notificação.'
                    : `Existem ${totalCount} novas notificações.`}
                </S.InfoAlert>
              )}
              {notifications?.length === 0 ? (
                <S.TextCard>Nenhuma notificação</S.TextCard>
              ) : (
                <S.ContainerCardNotifications className={'styled-scroll-s'}>
                  {notifications?.map((notification: INotification, index: number) => (
                    <S.CardNotification key={index} active={notification?.isRead}>
                      <S.BarNotification active={notification?.isRead} />
                      <S.ContainerTextCard>
                        {notification?.isRead ? (
                          <S.TextCard active={notification?.isRead}>Uma notificação lida</S.TextCard>
                        ) : (
                          <S.TextCard active={notification?.isRead}>
                            A etapa <strong>{truncateText(notification?.payload?.txMomentTitle, 36)} </strong>
                            teve a sua data alterada
                          </S.TextCard>
                        )}
                      </S.ContainerTextCard>
                      <S.BtnAction onClick={() => handleRemarkNewDate(notification)}>
                        <UilArrowRight size={16} />
                      </S.BtnAction>
                    </S.CardNotification>
                  ))}
                </S.ContainerCardNotifications>
              )}
            </S.ContentNotifications>
          </S.MenuContainer>

          <ModalActionComponent
            open={openModalNotification}
            actionCofirm={handleConfirmAll}
            actionConfirmNewDate={(itemNotification) => handleConfirmNewDate(itemNotification)}
            ActionRemarkNewDate={(itemNotification) => handleRemarkNewDate(itemNotification)}
            actionCloseModal={handleCloseModal}
            icon={<UilFileBookmarkAlt size={40} color="#0095FF" />}
            title="Opa! Atenção aqui! Parece que algumas de suas aulas tiveram as datas alteradas!"
            description="Você deseja apenas confirmar as alterações ou definir uma nova data?"
            btnColor={undefined}
            confirmCancelText="Aceitar todas as novas datas"
            infoWidth={100}
            isFullModal
            isNotification={true}
            data={notifications}
          />
        </>
      )}
    </>
  )
}
