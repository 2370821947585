import { Box, Divider } from '@mui/material'

import { AppBarComponent, CardClass, SkeletonComponent, ValidateDataSessionComponent } from '~/components'

import { HeaderComponent } from '~/containers/private/Classes/ListClasses'

import { useListClassesPageContext } from './context'
import * as S from './styles'

export const ListClassesView = () => {
  const {
    isLoading,
    handleGetClassesProps,
    params,
    groupedClasses,
    groupedGradeListFilter,
    handleChangeClassesSelected,
  } = useListClassesPageContext()

  return (
    <>
      <AppBarComponent />

      <S.Container>
        <S.Content>
          <HeaderComponent
            classes={groupedGradeListFilter}
            loading={isLoading}
            txName={params.txName}
            handleDisabledClasses={() => handleGetClassesProps({ showDisableClasses: !params.showDisableClasses })}
            showInactive={params.showDisableClasses}
            classesSelected={params.coGradeSelected}
            handleFilterByClassName={(value) => handleGetClassesProps({ txName: value })}
            handleChangeClassesSelected={handleChangeClassesSelected}
            isLoadingFilterClasses={isLoading}
          />
          <S.ContentList>
            {isLoading ? (
              <>
                <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                  <SkeletonComponent variant="text" width={100} height={32} />
                  <SkeletonComponent variant="text" width={70} height={32} />
                </Box>
                <Box display="flex" flexWrap="wrap" gap={2}>
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Box key={index} display="flex" flexDirection="column" gap={2} width="48%">
                      <Box>
                        <SkeletonComponent variant="rounded" width={'100%'} height={200} />
                      </Box>
                      <Divider />
                    </Box>
                  ))}
                </Box>
              </>
            ) : groupedClasses && Object.keys(groupedClasses).length > 0 ? (
              <>
                <Box>
                  <S.Description>Escolha uma turma para acessar as trilhas</S.Description>
                </Box>
                <Box>
                  {Object.keys(groupedClasses).map((key) => (
                    <S.ContentTxGrade key={key}>
                      <Box display={'flex'} alignItems={'center'} gap={'16px'}>
                        <S.TxGrade>{key}</S.TxGrade>
                        <S.Tag>
                          <span>{groupedClasses[key][0].txStage}</span>
                        </S.Tag>
                      </Box>
                      <S.ContentListTxGrade>
                        {groupedClasses[key].map((classTrack) => {
                          const textNoTrails =
                            classTrack.classTrackList?.length === undefined ? 'Escolher uma trilha' : 'Acessar Trilhas'
                          return (
                            <CardClass key={classTrack.coGrade} classTrack={classTrack} textNoTrails={textNoTrails} />
                          )
                        })}
                      </S.ContentListTxGrade>
                      <Divider />
                    </S.ContentTxGrade>
                  ))}
                </Box>
              </>
            ) : (
              <ValidateDataSessionComponent
                screen="students"
                height={50}
                title="Você não possui nenhuma trilha."
                description="Até o momento, nenhuma trilha foi cadastrada."
              />
            )}
          </S.ContentList>
        </S.Content>
      </S.Container>
    </>
  )
}
