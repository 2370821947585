import { useState } from 'react'

import { UilEye, UilFilter } from '@iconscout/react-unicons'
import { Checkbox } from '@mui/material'

import { ButtonComponent } from '~/components'

import { IHeaderComponentProps } from './Header.interfaces'
import * as S from './Header.styles'

export const HeaderComponent = ({
  loading,
  showInactive,
  handleDisabledClasses,
  classes,
  handleChangeClassesSelected,
  isLoadingFilterClasses,
  classesSelected,
}: IHeaderComponentProps) => {
  const [anchorElFilterMenu, setAnchorElFilterMenu] = useState<null | HTMLElement>(null)

  const handleOpenFilterMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElFilterMenu(event.currentTarget)
  }

  const handleCloseFilterMenu = () => setAnchorElFilterMenu(null)

  return (
    <S.HeaderContainer component="header">
      <S.Wrapper>
        <S.Title variant="h1">Trilhas</S.Title>
        <S.HeaderBtns>
          <ButtonComponent
            variant={showInactive ? 'solid' : 'animated'}
            size="small"
            text={showInactive ? 'Ocultar turmas inativas' : 'Exibir turmas inativas'}
            fontWeight="normal"
            iconEnd={<UilEye />}
            sizeLoading={12}
            animation={true}
            loading={loading}
            onClick={() => handleDisabledClasses()}
          />

          <S.BoxContainer>
            <ButtonComponent
              variant={'animated'}
              size="small"
              text={'Filtros'}
              fontWeight="normal"
              sizeLoading={12}
              iconEnd={<UilFilter size={18} color="#0095FF" />}
              animation={true}
              loading={loading}
              onClick={handleOpenFilterMenu}
            />
            <S.ButtonSelectClass
              anchorEl={anchorElFilterMenu}
              open={Boolean(anchorElFilterMenu)}
              onClose={handleCloseFilterMenu}
            >
              <S.BoxOverflow>
                <S.WrapperMenuItemFilter key="all">
                  <Checkbox
                    disabled={isLoadingFilterClasses}
                    checked={classesSelected.length === 0}
                    onChange={() => handleChangeClassesSelected('all')}
                  />
                  <span>Todos os anos</span>
                </S.WrapperMenuItemFilter>
                {classes?.map((item) => {
                  return (
                    <S.LabelMenuItemFilter key={item.coStage}>
                      <S.LabelCategoryGrade>{item.items[0].stage.txName}</S.LabelCategoryGrade>
                      {item.items.map((grade) => {
                        return (
                          <S.WrapperMenuItemFilter key={grade.coGrade}>
                            <Checkbox
                              disabled={isLoadingFilterClasses}
                              checked={classesSelected.includes(grade.coGrade)}
                              onChange={() => handleChangeClassesSelected(grade.coGrade)}
                            />
                            <span>{grade.txGrade}</span>
                          </S.WrapperMenuItemFilter>
                        )
                      })}
                    </S.LabelMenuItemFilter>
                  )
                })}
              </S.BoxOverflow>
            </S.ButtonSelectClass>
          </S.BoxContainer>
        </S.HeaderBtns>
      </S.Wrapper>
    </S.HeaderContainer>
  )
}
