export default {
  colors: {
    feedback: {
      success: {
        main: 'var(--feedback-color-success-pure)',
        light: 'var(--feedback-color-success-light)',
        medium: 'var(--feedback-color-success-medium)',
        dark: 'var(--feedback-color-success-dark)',
      },
      error: {
        main: 'var(--feedback-color-error-pure)',
        light: 'var(--feedback-color-error-light)',
        medium: 'var(--feedback-color-error-medium)',
        dark: 'var(--feedback-color-error-dark)',
      },
    },
    primaryLight: 'var(--brand-color-primary-light)',
    primaryMedium: 'var(--brand-color-primary-medium)',
    primaryPure: 'var(--brand-color-primary-pure)',
    primaryDark: 'var(--brand-color-primary-dark)',

    secondaryLight: 'var(--brand-color-secondary-light)',
    secondaryMedium: 'var(--brand-color-secondary-medium)',
    secondaryPure: 'var(--brand-color-secondary-pure)',
    secondaryDark: 'var(--brand-color-secondary-dark)',

    tertiaryLight: 'var(--brand-color-tertiary-light)',
    tertiaryMedium: 'var(--brand-color-tertiary-medium)',
    tertiaryPure: 'var(--brand-color-tertiary-pure)',
    tertiaryDark: 'var(--brand-color-tertiary-dark)',

    quaternaryLight: 'var(--brand-color-quaternary-light)',
    quaternaryMedium: 'var(--brand-color-quaternary-medium)',
    quaternaryPure: 'var(--brand-color-quaternary-pure)',
    quaternaryDark: 'var(--brand-color-quaternary-dark)',

    lowLight: 'var(--neutral-color-low-light)',
    lowMedium: 'var(--neutral-color-low-medium)',
    lowDark: 'var(--neutral-color-low-dark)',
    lowPure: 'var(--neutral-color-low-pure)',

    highPure: 'var(--neutral-color-high-pure)',
    highLight: 'var(--neutral-color-high-light)',
    highMedium: 'var(--neutral-color-high-medium)',
    highDark: 'var(--neutral-color-high-dark)',

    midLight: 'var(--neutral-color-mid-light)',
    midMedium: 'var(--neutral-color-mid-medium)',
    midPure: 'var(--neutral-color-mid-pure)',
    midDark: 'var(--neutral-color-mid-dark)',

    actionLight: 'var(--feedback-color-accent-light)',
    actionLow: 'var(--spacing-inset-sm)',
    actionMedium: 'var(--feedback-color-accent-medium)',
    actionPure: 'var(--feedback-color-accent-pure)',
    actionDark: 'var(--feedback-color-accent-dark)',

    positiveLight: 'var(--feedback-color-success-light)',
    positiveMedium: 'var(--feedback-color-success-medium)',
    positivePure: 'var(--feedback-color-success-pure)',
    positiveDark: 'var(--feedback-color-success-dark)',

    warningLight: 'var(--feedback-color-warning-light)',
    warningMedium: 'var(--feedback-color-warning-medium)',
    warningPure: 'var(--feedback-color-warning-pure)',
    warningDark: 'var(--feedback-color-warning-dark)',

    negativeLight: 'var(--feedback-color-error-light)',
    negativeMedium: 'var(--feedback-color-error-medium)',
    negativePure: 'var(--feedback-color-error-pure)',
    negativeDark: 'var(--feedback-color-error-dark)',
  },
  typography: {
    fontWeights: {
      black: 'var(--font-weight-black)',
      extrabold: 'var(--font-weight-extrabold)',
      bold: 'var(--font-weight-bold)',
      semibold: 'var(--font-weight-semibold)',
      medium: 'var(--font-weight-medium)',
      regular: 'var(--font-weight-regular)',
      light: 'var(--font-weight-light)',
      extralight: 'var(--font-weight-extralight)',
      thin: 'var(--font-weight-thin)',
    },
    fontSizes: {
      giant: 'var(--font-size-giant)',
      display: 'var(--font-size-display)',
      xxxl: 'var(--font-size-xxxl)',
      xxl: 'var(--font-size-xxl)',
      xl: 'var(--font-size-xl)',
      lg: 'var(--font-size-lg)',
      md: 'var(--font-size-md)',
      sm: 'var(--font-size-sm)',
      xs: 'var(--font-size-xs)',
      xxs: 'var(--font-size-xxs)',
      xxxs: 'var(--font-size-xxxs)',
    },
    lineHeights: {
      default: 'var(--line-height-default)',
      sm: 'var(--line-height-sm)',
      lg: 'var(--line-height-lg)',
      xl: 'var(--line-height-xl)',
      xxl: 'var(--line-height-xxl)',
    },
    headings: {
      headingGiant: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-giant)',
        lineHeight: 'var(--line-height-default)',
      },
      headingDisplay: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-display)',
        lineHeight: 'var(--line-height-default)',
      },
      headingXXXLarge: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-xxxl)',
        lineHeight: 'var(--line-height-default)',
      },
      headingXXLarge: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-xxl)',
        lineHeight: 'var(--line-height-default)',
      },
      headingXLarge: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-xl)',
        lineHeight: 'var(--line-height-default)',
      },
      headingLarge: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-lg)',
        lineHeight: 'var(--line-height-default)',
      },
      headingMedium: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-md)',
        lineHeight: 'var(--line-height-default)',
      },
      headingSmall: {
        fontWeight: 'var(--font-weight-bold)',
        fontSize: 'var(--font-size-sm)',
        lineHeight: 'var(--line-height-default)',
      },
    },
    caption: {
      large: {
        fontWeight: 'var(--font-weight-regular)',
        fontSize: 'var(--font-size-lg)',
        lineHeight: 'var(--line-height-sm)',
      },
      medium: {
        fontWeight: 'var(--font-weight-regular)',
        fontSize: 'var(--font-size-md)',
        lineHeight: 'var(--line-height-sm)',
      },
      small: {
        fontWeight: 'var(--font-weight-regular)',
        fontSize: 'var(--font-size-sm)',
        lineHeight: 'var(--line-height-sm)',
      },
      xSmall: {
        fontWeight: 'var(--font-weight-regular)',
        fontSize: 'var(--font-size-xs)',
        lineHeight: 'var(--line-height-sm)',
      },
    },
  },
  lineHeights: {
    default: 'var(--line-height-default)',
    sm: 'var(--line-height-sm)',
    lg: 'var(--line-height-lg)',
    xl: 'var(--line-height-xl)',
    xxl: 'var(--line-height-xxl)',
  },
  borderRadius: {
    none: 'var(--border-radius-none)',
    xs: 'var(--border-radius-xs)',
    sm: 'var(--border-radius-sm)',
    md: 'var(--border-radius-md)',
    lg: 'var(--border-radius-lg)',
    pill: 'var(--border-radius-pill)',
    circular: 'var(--border-radius-circular)',
  },
  borderWidth: {
    none: 'var(--border-width-none)',
    hairline: 'var(--border-width-hairline)',
    thin: 'var(--border-width-thin)',
    thick: 'var(--border-width-thick)',
    heavy: 'var(--border-width-heavy)',
  },
  opacityLevels: {
    semiOpaque: 'var(--opacity-level-semiopaque)',
    intense: 'var(--opacity-level-intense)',
    medium: 'var(--opacity-level-medium)',
    light: 'var(--opacity-level-light)',
    semiTransparent: 'var(--opacity-level-semitransparent)',
  },
  shadows: {
    level1: 'var(--shadow-level-1)',
    level2: 'var(--shadow-level-2)',
    level3: 'var(--shadow-level-3)',
    level4: 'var(--shadow-level-4)',
    level5: 'var(--shadow-level-5)',
    level6: 'var(--shadow-level-6)',
  },
  spacing: {
    quarck: 'var(--spacing-quarck)',
    nano: 'var(--spacing-nano)',
    xxxs: 'var(--spacing-xxxs)',
    xxs: 'var(--spacing-xxs)',
    xs: 'var(--spacing-xs)',
    sm: 'var(--spacing-sm)',
    md: 'var(--spacing-md)',
    lg: 'var(--spacing-lg)',
    xl: 'var(--spacing-xl)',
    xxl: 'var(--spacing-xxl)',
    xxxl: 'var(--spacing-xxxl)',
    huge: 'var(--spacing-huge)',
    giant: 'var(--spacing-giant)',
  },
  spacingInset: {
    quarck: 'var(--spacing-inset-quarck)',
    nano: 'var(--spacing-inset-nano)',
    xs: 'var(--spacing-inset-xs)',
    sm: 'var(--spacing-inset-sm)',
    md: 'var(--spacing-inset-md)',
    lg: 'var(--spacing-inset-lg)',
  },
}
