import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Box } from '@mui/material'

import {
  AppBarComponent,
  CardDescription,
  ContainerComponent,
  ModalBNCCComponent,
  SkeletonComponent,
  ValidateDataSessionComponent,
} from '~/components'
import CardDetailTrail from '~/components/Cards/CardDetailTrail/CardDetailTrail'
import { ExploreCard } from '~/components/Cards/ExpoloreCard/ExploreCard'
import StageSteps from '~/components/Navigation/StageSteps/StageSteps'

import { HeaderComponent } from '~/containers/private/Trails/TrailDetailsClass'
import { CardProjectComponent } from '~/containers/private/Trails/TrailDetailsProject'

import useIsMobile from '~/validations/isMobile'

import { CardInfoProject } from './CardInfo/CardInfo'
import { useListTrailDetailProjectPageContext } from './context'
import { IProjectProps } from './interfaces'
import * as S from './styles'

export const TrailDetailsProjectView = () => {
  const {
    openModalBNCC,
    handleOpenModalBNCC,
    handleCloseModalBNCC,
    handleOpenModalSchedule,
    isLoading,
    data,
    dataClassInfos,
    setLesson,
    lesson,
    dataProject,
    isLoadingProject,
    isLoadingExplore,
    setExplore,
    exploreData,
    setOpenDetail,
    openDetail,
  } = useListTrailDetailProjectPageContext()

  const { classId } = useParams()

  const track = data
  const coStage = dataClassInfos?.class.items?.[0]?.schoolGrade?.grade?.stage?.coStage
  const txGrade = dataClassInfos?.class.items?.[0]?.schoolGrade?.grade?.txGrade
  const txClassShift = dataClassInfos?.class.items?.[0]?.classShift?.txClassShift
  const txSchoolGradeName = dataClassInfos?.class.items?.[0]?.txName
  const sortedLessons = track?.stage?.sort((a, b) => a.nuOrder - b.nuOrder) || []
  const [trailIndex, setTrailIndex] = useState<number | null>(null)
  const isMobile = useIsMobile()
  const [currentIndex, setCurrentIndex] = useState(0)
  const itemsPerPage = track?.stage?.length <= 4 ? 4 : 3

  const totalCards = track?.stage?.length || 0

  const maxInitialIndex = totalCards - itemsPerPage
  const adjustedCurrentIndex = Math.min(currentIndex, maxInitialIndex)
  function handleAllLessons() {
    setTrailIndex(null)
    setExplore(null)
    setLesson(null)
    setOpenDetail(false)
  }

  const handleActiveCard = (index: number) => {
    setTrailIndex(index)
    setLesson(sortedLessons[index])
  }
  const handlePrevClick = () => {
    if (lesson) {
      if (lesson.nuOrder > 1) {
        setLesson(sortedLessons[lesson.nuOrder - 2])
      }
    } else {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 3)
      }
    }
    setOpenDetail(false)
  }

  const handleNextClick = () => {
    if (lesson) {
      if (lesson.nuOrder < totalCards) {
        setLesson(sortedLessons[lesson.nuOrder])
      }
    } else {
      if (currentIndex < totalCards - itemsPerPage) {
        setCurrentIndex(currentIndex + 3)
      }
    }
    setOpenDetail(false)
  }

  const handleSelect = (index: number) => {
    setTrailIndex(index)
    setLesson(sortedLessons[index])
    setOpenDetail(false)
  }

  const handleExplore = (project: any, nuOrder: number) => {
    setOpenDetail(true)
    setExplore({
      project: project,
      nuOrder: nuOrder,
    })
  }

  const handleBack = () => {
    setOpenDetail(false)
  }

  const isPrevDisabled = currentIndex === 0
  const isNextDisabled = currentIndex >= totalCards - itemsPerPage

  return (
    <S.ContainerScroll>
      <AppBarComponent />

      <ContainerComponent>
        <HeaderComponent
          loading={isLoading}
          path={`/projects-available/${classId}`}
          position={track?.idTrack || 0}
          title={track?.txTitle || ''}
          components={track?.component || []}
          actionOpenModalBNCC={handleOpenModalBNCC}
          actionSelectTrail={handleOpenModalSchedule}
          coStage={coStage}
          txGrade={txGrade}
          txClassShift={txClassShift}
          txSchoolGradeName={txSchoolGradeName}
        />

        {lesson ? (
          <S.ContentList component="ul">
            <StageSteps
              data={track}
              onChangeTrailIndex={trailIndex}
              handleAllLessons={handleAllLessons}
              handlePrevClick={handlePrevClick}
              handleNextClick={handleNextClick}
              isPrevDisabled={lesson ? lesson.nuOrder === 1 : isPrevDisabled}
              isNextDisabled={lesson ? lesson.nuOrder === totalCards : isNextDisabled}
              currentIndex={lesson.nuOrder}
              handleSelect={handleSelect}
              hasLesson={true}
              itemsPerPage={1}
            />

            {isLoadingProject ? (
              <Box alignItems={'center'} mt={4} display="flex" flexDirection="column" gap={2}>
                <SkeletonComponent variant="rounded" width={'100%'} height={'120px'} />
                <SkeletonComponent variant="rounded" width={'100%'} height={'120px'} />
                <SkeletonComponent variant="rounded" width={'100%'} height={'120px'} />
                <SkeletonComponent variant="rounded" width={'100%'} height={'120px'} />
              </Box>
            ) : (
              <>
                {!openDetail && dataProject && (
                  <CardInfoProject
                    title={dataProject?.txTitle}
                    nuOrder={dataProject?.nuOrder}
                    description={dataProject?.txDescription}
                    components={dataProject?.projectStage}
                    handleExplore={handleExplore}
                    loading={isLoadingProject}
                  />
                )}
              </>
            )}

            {openDetail && exploreData && (
              <S.ContentList>
                <S.TitleRow>
                  <S.NuOrder>{dataProject?.nuOrder}</S.NuOrder>
                  <S.Title>{dataProject?.txTitle}</S.Title>
                </S.TitleRow>
                <ExploreCard
                  isLoading={isLoadingExplore}
                  evidences={exploreData?.evidence}
                  title={exploreData?.txProjectTitle}
                  activityOrientations={exploreData?.projectStageOrientations}
                  description={exploreData?.txProjectDescription}
                  handleBack={handleBack}
                  mediaInfo={exploreData?.mediaInformation}
                  categories={exploreData?.categories}
                  variant="P2"
                />
              </S.ContentList>
            )}
          </S.ContentList>
        ) : (
          <>
            <S.ContentList component="ul">
              <CardDescription description={track?.txDescription} />

              <S.Label>Projetos da Trilha</S.Label>

              {!isLoading && !track?.project?.length ? (
                <ValidateDataSessionComponent
                  screen="home"
                  title="Você não tem nenhuma trilha disponível."
                  description="Entre em contato com a coordenação da sua escola ou com o suporte Lekto para mais informações."
                />
              ) : (
                <>
                  {isLoading ? (
                    <>
                      {Object.keys(new Array(5).fill(null)).map((_, index: number) => (
                        <CardProjectComponent loading={isLoading} key={index} />
                      ))}
                    </>
                  ) : (
                    <>
                      {track?.project?.map((project: IProjectProps) => (
                        <CardProjectComponent
                          key={project.idProject}
                          skill={project.category?.txName}
                          txImagePath={project.category?.txImagePath}
                          title={project.txTitle}
                          description={project.txDescription}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </S.ContentList>

            <S.ContainerLesson>
              <StageSteps
                data={track}
                onChangeTrailIndex={trailIndex}
                handleAllLessons={handleAllLessons}
                handlePrevClick={handlePrevClick}
                handleNextClick={handleNextClick}
                isPrevDisabled={isPrevDisabled}
                isNextDisabled={isNextDisabled}
                adjustedCurrentIndex={adjustedCurrentIndex}
                currentIndex={currentIndex}
                itemsPerPage={itemsPerPage}
                handleSelect={handleSelect}
              />
              {!openDetail && (
                <S.CardSliderResumeContainer isMobile={Boolean(isMobile)}>
                  {sortedLessons
                    .slice(adjustedCurrentIndex, adjustedCurrentIndex + itemsPerPage)
                    .map((lesson, index) => (
                      <CardDetailTrail
                        key={index}
                        index={index}
                        dataTrail={lesson}
                        isProjectTrailNotgenereated={true}
                        handleActiveCard={() => handleActiveCard(lesson?.nuOrder - 1)}
                        variant="P2"
                      />
                    ))}
                </S.CardSliderResumeContainer>
              )}
            </S.ContainerLesson>
          </>
        )}
      </ContainerComponent>

      <ModalBNCCComponent
        open={openModalBNCC}
        txGuidanceBNCC={track?.txGuidanceBNCC}
        actionCloseModal={handleCloseModalBNCC}
      />
    </S.ContainerScroll>
  )
}
