import { UilArrowRight, UilCalender, UilCheck } from '@iconscout/react-unicons'

import { useQueryGraphQLClient } from '~/server/GraphQLClient'

import { ButtonComponent, ChipComponent } from '~/components'

import { GET_CLASS_INFOS_BY_CLASSID } from '~/pages/private/Trails/ListTrailsProject/schemas.queries'

import { getFormattedLabelWithDay } from '~/validations/formattLabelWithDay'
import { translateCoStage } from '~/validations/translateCoStage'
import { truncateText } from '~/validations/truncateText'

import { ICardNotificationProps } from './CardNotification.interface'
import * as S from './CardNotification.styles'

export default function CardNotification({
  actionConfirmNewDate,
  ActionRemarkNewDate,
  itemNotification,
}: ICardNotificationProps) {
  const { isLoading: isLoadingClassInfos, data: dataClassInfos } = useQueryGraphQLClient(
    'network',
    'GET_CLASS_INFOS_BY_CLASSID',
    GET_CLASS_INFOS_BY_CLASSID,
    {
      classId: Number(itemNotification.payload.idClass),
      showDisabledClasses: true,
    },
  )
  const item = dataClassInfos?.class?.items[0]
  const coStage = item?.schoolGrade?.grade?.stage?.coStage
  const txClassShift = item?.classShift?.txClassShift
  const txGrade = item?.schoolGrade?.grade?.txGrade
  const txName = item?.txName
  return (
    <S.Container>
      <S.ContainerHeader>
        <S.DescriptionHeader>Nova data</S.DescriptionHeader>
        <ChipComponent
          type={'normal'}
          icon={<UilCalender size={15} />}
          label={getFormattedLabelWithDay(String(itemNotification.payload.dtMomentNew))}
        />
      </S.ContainerHeader>

      <S.Content>
        <S.LessonDescription>{itemNotification.payload.txMomentTitle}</S.LessonDescription>
      </S.Content>

      {isLoadingClassInfos ? (
        <span>Carregando...</span>
      ) : (
        <S.Content>
          <S.ContainerTrackClass>
            <S.ClassSpanInfo>{truncateText(translateCoStage(coStage) || '', 18)}</S.ClassSpanInfo>
            <S.ClassSpanInfo>{txClassShift}</S.ClassSpanInfo>
            <S.ClassSpanInfo>{txGrade}</S.ClassSpanInfo>
            <S.ClassSpanInfo>{txName}</S.ClassSpanInfo>
          </S.ContainerTrackClass>
        </S.Content>
      )}

      <S.ContainerBtns>
        <ButtonComponent
          text={'Aceitar nova data'}
          variant={'solid'}
          size={'small2'}
          onClick={() => actionConfirmNewDate(itemNotification)}
          fullWidth
          iconEnd={<UilCheck size={16} color="#fff" />}
          fontWeight={'normal'}
        />
        <ButtonComponent
          text={'Definir nova data'}
          variant={'outline'}
          size={'small2'}
          onClick={() => ActionRemarkNewDate(itemNotification)}
          fullWidth
          iconEnd={<UilArrowRight size={16} color="#0095FF" />}
          fontWeight={'normal'}
        />
      </S.ContainerBtns>
    </S.Container>
  )
}
